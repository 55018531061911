import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "./style.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useSelector } from "react-redux";
import axios from "axios";
import { ErrorLogger } from "../../util/errorLogger";
import {
  Company,
  IBridgeAccount,
  IBridgeItem,
  IS3,
  ISubCategory,
  ITransaction,
  User,
} from "../../interfaces";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import UserProsp from "../../assets/images/svg/user-blue.svg";
import UserChat from "../../assets/images/avatar.png";
import { Watch } from "typescript";
import {
  RiBriefcase4Line,
  RiCalendar2Line,
  RiCameraLine,
  RiCheckboxCircleLine,
  RiCommunityLine,
  RiDownload2Fill,
  RiFlagLine,
  RiIndeterminateCircleFill,
  RiMapPinLine,
} from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import fr from "date-fns/locale/fr";
import moment from "moment";
import "moment/locale/fr";
import Select from "react-select";
import { TransactionMediums, UserTypes } from "../../util/context";
import DatePicker, { registerLocale } from "react-datepicker";

import { BsMailbox, BsTrashFill } from "react-icons/bs";
import { BootyPagination } from "../../components/table/pagination";
import DataTable from "react-data-table-component";
import useAxios from "../../util/hooks/useAxios";
import MoneyIconGreen from "../../assets/images/svg/euro-icon-green.svg";
import MoneySacIconGreen from "../../assets/images/svg/money-sac-green.svg";
import CalenderIconGreen from "../../assets/images/svg/calender-icon-green.svg";
import { toast } from "react-toastify";
import CustomSwitch from "../../components/CustomSwitch/Switch";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

type GeneralFormValues = {
  file: any;
};

type CreateGeneralFormValues = {
  amount: number | null;
  label: string | null;
  date: string | null;
  vat: string | null;
  iban_id: { value: string; label: string } | null;
  category: { value: string; label: string } | null;
  carbVehType: { value: string; label: string } | null;
  documents?: any;
  modalType?: string | null;
  relatedToFactureType?: string | null;
  sub_category_id?: string | null;
  facture_ids: string[] | null;
};

const { API_URL } = config[process.env.NODE_ENV];

const TransactionsImport = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [successInsert, setSuccessInsert] = useState<string[]>([]);
  const [failInsert, setFailInsert] = useState<string[]>([]);
  const [fileInputKey, setFileInputKey] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [duplicationModal, setDuplicationModal] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  const [insertErrorMessage, setInsertErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  const [successMessage, setSuccessMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  const [bridegAccounts, setBridgeAccounts] = useState<IBridgeAccount[]>([]);
  const [accountFileMapping, setAccountFileMapping] = useState<
    { name: string; iban: string }[]
  >([]);
  const [switchValue, setSwitchValue] = useState("enc");
  const [addNoteFraisModal, setAddTransactionModal] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD hh:mm")
  );
  const [createLoading, setCreateLoading] = useState<boolean>(false);

  const columnsArr: any = [
    {
      name: "Date",
      selector: (row: any) => moment(row.date).format("YYYY/MM/DD"),
    },
    {
      name: "Transaction",
      selector: (row: any) => row.label,
    },
    {
      name: "Montant",
      selector: (row: any) => row.amount,
    },
  ];
  const handleRowSelected = (state: {
    selectedRows: React.SetStateAction<never[]>;
  }) => setSelectedRows(state.selectedRows);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { id } = useParams();

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  // const { file: _file } = watch();

  // const { ref: fileRef, ...file } = register("file");

  let api = useAxios();

  const uploadTransactions: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);
      if (files.length === 0) {
        setErrorMessage({
          type: "not_valid",
          message: "Veuillez remplir tous les champs nécessaires du formulaire",
        });
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("companyId", id as string);

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        formData.append(`bank_statement-[${index}]`, element);
        if (accountFileMapping.length > 0) {
          const fileIbanIndex = accountFileMapping.findIndex(
            (elt) => elt.name === element.name
          );
          if (fileIbanIndex > -1) {
            formData.append(
              `bank_statement-[${index}]-iban`,
              accountFileMapping[fileIbanIndex].iban
            );
          }
        }
      }

      const { data } = await api.post(
        `/api/documents/bank-statement`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data) {
        if (data.uploaded.length > 0) {
          setSuccessMessage({
            type: "upload_success",
            message: (
              <Row>
                <Col md={12}>
                  Les transactions ont été insérées avec succès pour les
                  fichiers :{" "}
                </Col>
                <Col md={12}>
                  <ul>
                    {data.uploaded.map(
                      (
                        elt:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined,
                        key: React.Key | null | undefined
                      ) => (
                        <li key={key}>
                          <RiCheckboxCircleLine /> {elt}
                        </li>
                      )
                    )}
                  </ul>
                </Col>
              </Row>
            ),
          });
        }

        if (data.notUploaded.length > 0) {
          setErrorMessage({
            type: "upload_pending",
            message: (
              <Row>
                <Col md={12}>On peut pas traité : </Col>
                <Col md={12}>
                  <ul>
                    {data.notUploaded.map(
                      (
                        elt:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined,
                        key: React.Key | null | undefined
                      ) => (
                        <li key={key}>
                          <RiIndeterminateCircleFill /> {elt}
                        </li>
                      )
                    )}
                  </ul>
                </Col>
              </Row>
            ),
          });
        }

        if (data.duplicated.length > 0) {
          setDuplicates(data.duplicated);
          setDuplicationModal(true);
        }
      }

      reset();
      setLoading(false);
      setFiles(null);
    } catch (error: any) {
      ErrorLogger("updating client form", error);
      setLoading(false);
      reset();
      setErrorMessage({
        type: "upload_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  const insertTransaction = async () => {
    try {
      setLoading(true);
      setInsertErrorMessage(null);
      if (selectedRows.length === 0) {
        setInsertErrorMessage({
          type: "not_valid_duplicates",
          message: "Veuillez choisir au moins 1 trannsaction",
        });
        setLoading(false);
        return;
      }
      for (let transaction of selectedRows) {
        const formData = new FormData();

        formData.append("amount", (transaction as any).amount as any);
        formData.append("label", (transaction as any).label as string);
        formData.append("status", 100 as any);
        formData.append("companyId", id as string);
        formData.append(
          "date",
          moment((transaction as any).date)
            .set({ hour: 12, minute: 0 })
            .format("YYYY-MM-DD hh:mm") as string
        );
        formData.append("medium", TransactionMediums.BankStatement);

        if ((transaction as any).iban_id) {
          formData.append("iban_id", (transaction as any).iban_id);
        }

        await api.post(`/api/Transaction/Create`, formData, {
          headers: {
            "x-access-token": creds.token,
          },
        });
      }

      setLoading(false);
      setDuplicationModal(false);
      reset();
      setInsertErrorMessage(null);
      setSelectedRows([]);
      setDuplicates([]);
    } catch (error: any) {
      ErrorLogger("adding duplicate transactions", error);
      setLoading(false);
    }
  };

  const resetsFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const resetState = () => {
    reset();
    setLoading(false);
    setErrorMessage(null);
    setSuccessMessage(null);
    setFailInsert([]);
    setSuccessInsert([]);
    setFiles(null);
    resetsFileInput();
    setAccountFileMapping([]);
  };

  const getBridgeAccounts = async () => {
    try {
      const { data: qontoAccounts } = await api.post(
        `/api/BridgeItem/All`,
        {
          where: {
            company_id: creds.role !== UserTypes.Client ? id : creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const items = qontoAccounts.data as IBridgeItem[];

      setBridgeAccounts(
        items
          .reduce(
            (acc, curr) => [...acc, ...curr.bridge_accounts],
            [] as IBridgeAccount[]
          )
          .filter((elt) => elt.iban)
      );
    } catch (error: any) {
      ErrorLogger("getting indemnities", error);
    }
  };
  const mapAccountFile = (data: any) => {
    try {
      const { e, elt: file } = data;
      const bridegAccountIndex = bridegAccounts.findIndex(
        (elt) => elt.id === e.value
      );
      if (bridegAccountIndex > -1) {
        const iban = bridegAccounts[bridegAccountIndex].iban;
        setAccountFileMapping((prevState) => {
          let arr = [...prevState];
          const fileIndex = arr.findIndex((elt) => elt.name === file.name);
          if (fileIndex > -1) {
            arr[fileIndex] = {
              ...arr[fileIndex],
              iban,
            };
          } else {
            arr.push({
              name: file.name,
              iban,
            });
          }

          return arr;
        });
      }
    } catch (error) {
      console.log("mapAccountFile : ", error);
    }
  };

  const {
    watch: createWatch,
    control: createControl,
    register: createRegister,
    handleSubmit: createSubmit,
    reset: createReset,
    setValue: createSetValue,
    formState: { errors: createErrors },
    getValues,
  } = useForm<CreateGeneralFormValues>({});

  const { category: _category } = createWatch();

  const createTransaction: SubmitHandler<CreateGeneralFormValues> = async (
    form: CreateGeneralFormValues
  ) => {
    try {
      setCreateLoading(true);
      toast.dismiss();
      if (!form.label || !form.amount || !form.date) {
        setCreateLoading(false);
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }

      const formData = new FormData();

      formData.append("label", form.label as string);
      formData.append("status", 100 as any);
      formData.append("companyId", id as string);
      formData.append("date", moment(form.date).format("YYYY-MM-DD") as string);
      formData.append("medium", TransactionMediums.BankStatement);

      formData.append(
        "amount",
        switchValue === "dec"
          ? `-${(form as any).amount as any}`
          : `${(form as any).amount as any}`
      );

      if (form.iban_id) {
        const accountIndex = bridegAccounts.findIndex(
          (elt) => elt.id === form.iban_id?.value
        );
        if (accountIndex > -1) {
          formData.append("iban_id", bridegAccounts[accountIndex].iban);
        }
      }

      const { data } = await api.post(`/api/Transaction/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setCreateLoading(false);

      resteForm();
      setAddTransactionModal(false);
      toast.success(`Votre transaction a été ajoutée avec succès.`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("transaction creation form", error);
      setCreateLoading(false);
      toast.error(
        "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard",
        {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const { ref: labelRef, ...label } = createRegister("label");
  const { ref: amountRef, ...amount } = createRegister("amount");
  const resteForm = () => {
    createReset({
      label: null,
      amount: null,
      date: null,
      category: null,
      modalType: null,
      facture_ids: [],
    });
    setErrorMessage(null);
    setSwitchValue("enc");
  };

  useEffect(() => {
    getBridgeAccounts();
  }, []);

  return (
    <div className="page">
      <Card className="card-Table table-primary card-body">
        <div className="transactions-import-actions">
          <Button
            color="primary"
            type="button"
            onClick={() => {
              setAddTransactionModal(true);
            }}
          >
            Ajouter Une Transaction
          </Button>
        </div>

        <form onSubmit={handleSubmit(uploadTransactions)}>
          <div className="file-uploader col-12 col-lg-7 mx-auto">
            <h3>Importation de nouvelles transactions</h3>

            <FormGroup>
              <Label for="proofOfIdentity">Relevé Bancaire</Label>
              <div className="form-icon icon-start form-file file-secondary">
                <span className="label-file">Déposez le fichier ici</span>
                <label className="file-box ">
                  <RiDownload2Fill />
                  <Input
                    id="proofOfIdentity"
                    placeholder="..."
                    className="form-secondary"
                    type="file"
                    multiple
                    onClick={(event) => {
                      (event.target as HTMLInputElement).value =
                        null as unknown as string;
                    }}
                    onChange={(e) => {
                      setErrorMessage(null);
                      setSuccessMessage(null);
                      setFiles((prevState: any) => [
                        ...(prevState || []),
                        ...(e.target.files as any),
                      ]);
                      resetsFileInput();
                    }}
                  />
                  <span> Télécharger d'ici</span>
                </label>
              </div>
            </FormGroup>
          </div>
          <Row className="status-messages">
            <Col md={6}>
              <div className="list-Files">
                {files &&
                  files.length > 0 &&
                  files.map(
                    (
                      elt: {
                        name:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      key: React.Key | null | undefined
                    ) => (
                      <div className="import-transactions-wrapper">
                        <div
                          className="import-transactions file-box-item-with-clickers"
                          key={key}
                        >
                          <span className="file-viewer-click">
                            <AttacheIcon />
                            <span className="file-name">{elt.name}</span>
                          </span>
                          <span
                            className="file-delete-click"
                            onClick={() => {
                              setErrorMessage(null);
                              setFiles((prevState: any) => {
                                return prevState.filter(
                                  (file: { name: any }) =>
                                    file.name !== (elt as any).name
                                );
                              });
                            }}
                          >
                            <BsTrashFill />
                          </span>
                        </div>
                        <Select
                          options={bridegAccounts.reduce(
                            (acc: any, curr) => [
                              ...acc,
                              {
                                label: curr.name,
                                value: curr.id,
                              },
                            ],
                            [] as { value: string; label: string }[]
                          )}
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="custom-select form-secondary"
                          onChange={(e) => mapAccountFile({ e, elt })}
                        />
                      </div>
                    )
                  )}
              </div>
            </Col>
            <Col md={6}>
              {errorMessage?.type === "upload_error" && (
                <div className="d-flex align-items-center text-danger">
                  {errorMessage?.message}
                </div>
              )}
              {errorMessage?.type === "upload_pending" && (
                <div className="d-flex align-items-center text-danger">
                  {errorMessage?.message}
                </div>
              )}
              {loading && (
                <div>
                  <Spinner color="info" type="border" size={"sm"}>
                    Loading...
                  </Spinner>{" "}
                  Traitement , veuillez patienter...
                </div>
              )}
              {successMessage?.type === "upload_success" && (
                <div className="d-flex align-items-center text-success">
                  {successMessage?.message}
                </div>
              )}
              {errorMessage?.type === "not_valid" && (
                <div className="d-flex align-items-center text-danger">
                  {errorMessage?.message}
                </div>
              )}
            </Col>
          </Row>
          <div className="action-buttons">
            <Button
              color="secondary"
              outline
              type="button"
              disabled={loading}
              onClick={() => resetState()}
            >
              Réinitialiser
            </Button>
            <Button color="secondary" type="submit" disabled={loading}>
              Importation
            </Button>
          </div>
        </form>
      </Card>
      <div className="openbtn text-center">
        {/* select sync account */}
        <Modal
          className="modal-primary modal-dialog-centered duplicates-modal"
          isOpen={duplicationModal}
          toggle={() => {
            setDuplicationModal(false);
            setSelectedRows([]);
            setDuplicates([]);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDuplicationModal(false);
              setSelectedRows([]);
              setDuplicates([]);
            }}
          >
            Transactions dupliquées
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              <Row className="duplicates-actions">
                <Col md={12} className="duplicates-actions-buttons mb-2">
                  <Button
                    color="primary"
                    onClick={() => {
                      setDuplicationModal(false);
                      reset({
                        file: null,
                      });
                      setErrorMessage(null);
                    }}
                  >
                    Ignorer tout
                  </Button>
                  <Button
                    color="primary"
                    outline
                    className="ms-2"
                    onClick={async () => {
                      await insertTransaction();
                    }}
                  >
                    Insérer les transactions sélectionnées
                  </Button>
                </Col>
                {insertErrorMessage?.type === "not_valid_duplicates" && (
                  <div
                    className="d-flex align-items-center text-danger mb-2"
                    color="danger"
                  >
                    {insertErrorMessage?.message}
                  </div>
                )}
              </Row>
            ) : (
              <></>
            )}

            <DataTable
              columns={columnsArr}
              data={duplicates}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              noDataComponent={<p>Il n'y a aucun data à afficher</p>}
              pagination
              progressPending={loading}
              progressComponent={
                <>
                  <div className="d-flex align-items-center text-success">
                    <h5>Insertion des transactions, veuillez patienter</h5>
                  </div>
                </>
              }
              paginationComponent={(props: any) => {
                const customProps = { ...props, color: "primary" };
                return <BootyPagination {...customProps} />;
              }}
            />
          </ModalBody>
        </Modal>

        {/*create transaction*/}
        <Modal
          className="modal-primary modal-dialog-centered"
          isOpen={addNoteFraisModal}
          toggle={() => {
            setAddTransactionModal(false);
            resteForm();
          }}
        >
          <ModalHeader
            toggle={() => {
              setAddTransactionModal(false);
              resteForm();
            }}
          >
            Ajouter une nouvelle transaction
          </ModalHeader>
          <form onSubmit={createSubmit(createTransaction)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="label">Libellé * </Label>
                      <Input
                        id="label"
                        innerRef={labelRef}
                        {...label}
                        type="text"
                        className="form-primary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-primary ">
                        {<ReactSVG src={MoneyIconGreen} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Date * </Label>
                      <Controller
                        control={createControl}
                        name="date"
                        render={({ field }) => (
                          <DatePicker
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-primary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      <span className="icon icon-primary ">
                        {<ReactSVG src={CalenderIconGreen} />}
                      </span>
                    </FormGroup>
                  </Col>
                  {bridegAccounts.length > 0 && (
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Compte associé</Label>
                        <Controller
                          name="iban_id"
                          control={createControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={bridegAccounts.reduce(
                                (acc: any, curr) => [
                                  ...acc,
                                  {
                                    label: curr.name,
                                    value: curr.id,
                                  },
                                ],
                                [] as { value: string; label: string }[]
                              )}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="amount">Montant * </Label>
                      <Input
                        id="amount"
                        innerRef={amountRef}
                        {...amount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        step={0.01}
                        className="form-primary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-primary ">
                        {<ReactSVG src={MoneySacIconGreen} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start manual-tr-type">
                      <div className="box-list">
                        <label className="form-label">Type</label>
                        <div className="custom-switch admin-cabinet-custom-switch">
                          <span className="label label-enc">Encaissement</span>
                          <CustomSwitch
                            id="admin-cabinet-switch"
                            switchValue={switchValue}
                            setSwitchValue={setSwitchValue}
                            leftValue = "enc"
                            rightValue="dec"
                          />
                          <span className="label label-dec">Décaissement</span>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {errorMessage?.type === "invalid_form" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
                {errorMessage?.type === "undexpected_issue" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setAddTransactionModal(false);
                  resteForm();
                }}
                type="button"
                disabled={createLoading}
              >
                Annuler
              </Button>
              <Button color="primary" type="submit" disabled={createLoading}>
                {createLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Ajouter"
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default TransactionsImport;

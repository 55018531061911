import React, { useEffect, useState } from "react";
import "./style.scss";
import fileDownload from 'js-file-download'
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
// import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  LoadError,
  RenderPage,
  RenderPageProps,
  Viewer,
} from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import DocViewer, {
  BMPRenderer,
  DocRenderer,
  HTMLRenderer,
  IHeaderOverride,
  ImageProxyRenderer,
  JPGRenderer,
  MSDocRenderer,
  MSGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
} from "@cyntler/react-doc-viewer";
import { RiDownload2Fill } from "react-icons/ri";
import DownloadLink from "react-download-link";
import useAxios from "../../util/hooks/useAxios";
import { toast } from "react-toastify";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const renderPage: RenderPage = (props: RenderPageProps) => (
  <>
    {props.canvasLayer.children}
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    >
      <div
        style={{
          color: "rgba(90, 199, 176, 0.5)",
          fontSize: `${5 * props.scale}rem`,
          fontWeight: "bold",
          textTransform: "uppercase",
          transform: "rotate(-45deg)",
          userSelect: "none",
        }}
      >
        NOLY COMPTA
      </div>
    </div>
    {props.annotationLayer.children}
    {props.textLayer.children}
  </>
);

const renderError = (error: LoadError) => {
  console.log("error here ",error)
  let message = "";
  switch (error.name) {
    case "InvalidPDFException":
      message = "Le document n'est pas valide ou est corrompu";
      break;
    case "MissingPDFException":
      message = "Le document est manquant";
      break;
    case "UnexpectedResponseException":
      message = "Impossible de charger le document";
      break;
    default:
      message = "Impossible de charger le document pour le moment, mais vous pouvez le télécharger en cliquant sur le bouton de téléchargement.";
      break;
  }

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#e53e3e",
          borderRadius: "0.25rem",
          color: "#fff",
          padding: "0.5rem",
        }}
      >
        {message}
      </div>
    </div>
  );
};

const MyCustomImageRenderer: DocRenderer = ({
  mainState: { currentDocument },
}) => {
  if (!currentDocument) return null;

  return (
    <div id="my-png-renderer">
      <img id="png-img" src={currentDocument.fileData as string} />
    </div>
  );
};

MyCustomImageRenderer.fileTypes = ["png", "PNG"," HEIC","JPEG","JPG", "image/png"];
MyCustomImageRenderer.weight = 1;

const CustomHeader: IHeaderOverride = (state) => {
  let api = useAxios();
  const getDataFromURL = async (url: string) => {
    const { data } = await api.get(url, {
      responseType: "blob",
    });
    return data;
  };

  return (
    <>
      {state.documents && state.documents.length > 0 && (
        <div className="image-downloader">
          <RiDownload2Fill />
          <DownloadLink
            label="Télécharger"
            filename={(state.documents[0].uri || "").substring(
              (state.documents[0].uri || "").lastIndexOf("/") + 1
            ).replace("?origin=" + window.location.host, "")}
            exportFile={async () =>
              await getDataFromURL(state.documents[0].uri)
            }
          />
        </div>
      )}
    </>
  );
};

export const DocView = ({
  url,
  hasWaterMark,
  fileType,
}: {
  url: string | null;
  hasWaterMark?: boolean;
  fileType?: string;
}) => {
  const url2blob = async (url: string) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();

      setDispalyedURlL({
        url,
        type: blob.type,
        location: "local",
      });
    } catch (err: any) {
      console.error(err);
    }
  };

  const [displayedURL, setDispalyedURlL] = useState<{
    type: string;
    url: string | null;
    location: string;
  } | null>();

  useEffect(() => {
    if (String(url) === "null") {
      setDispalyedURlL(null)
    }
    if (url && url?.includes("blob:")) {
      url2blob(url);
    }
    if (url && !url?.includes("blob:")) {
      setDispalyedURlL({
        url: url + "?origin=" + window.location.host,
        type: url?.split(".").pop()!,
        location: "remote",
      });
    }
  }, [url]);

  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file) => {
        return `${String(url).toUpperCase()}`;
      },
    },
    searchPlugin: {
      keyword: "PDF",
    },
  });
  const { Toolbar } = toolbarPluginInstance;

  return (
    <>
      {displayedURL && (
        <>
          {displayedURL?.location === "local" ? (
            <>
              {displayedURL.type && (
                <>
                  {displayedURL?.type.toLowerCase().includes("pdf") ? (
                    <>
                      <Toolbar />
                      <Viewer
                        fileUrl={displayedURL.url!}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                      />
                    </>
                  ) : (
                    <>
                      <DocViewer
                        documents={[
                          {
                            uri: displayedURL.url!,
                            fileType: displayedURL.type,
                          },
                        ]}
                        pluginRenderers={[
                          BMPRenderer,
                          HTMLRenderer,
                          ImageProxyRenderer,
                          JPGRenderer,
                          MSDocRenderer,
                          MSGRenderer,
                          PDFRenderer,
                          PNGRenderer,
                          TIFFRenderer,
                          TXTRenderer,
                          MyCustomImageRenderer
                        ]}
                        config={{
                          noRenderer: {
                            overrideComponent: renderError as any,
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {displayedURL.type && (
                <>
                  {displayedURL?.type.toLowerCase().includes("pdf") ? (
                    <>
                      <Toolbar />
                      <Viewer
                        fileUrl={displayedURL.url!}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                      />
                    </>
                  ) : (
                    <>
                      <DocViewer
                        documents={[
                          {
                            uri: displayedURL?.url!,
                            fileType: displayedURL.type,
                          },
                        ]}
                        pluginRenderers={[
                          BMPRenderer,
                          HTMLRenderer,
                          ImageProxyRenderer,
                          JPGRenderer,
                          MSDocRenderer,
                          MSGRenderer,
                          PDFRenderer,
                          PNGRenderer,
                          TIFFRenderer,
                          TXTRenderer,
                          MyCustomImageRenderer
                        ]}
                        config={{
                          noRenderer: {
                            overrideComponent: renderError as any,
                          },
                          header: {
                            disableFileName: true,
                            overrideComponent: CustomHeader,
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const extensionsSupported = ["pdf", "bmp", "html", "jpg", "jpeg", "png", "tiff", "txt"]

const FileViewer = ({
  url,
  viewModal,
  setUrl,
  setViewModal,
  hasWaterMark,
  fileType,
}: {
  url: string | null;
  viewModal: boolean;
  setViewModal: (value: React.SetStateAction<boolean>) => void;
  setUrl: (value: any) => void;
  hasWaterMark?: boolean;
  fileType?: string;
}) => {
  let api = useAxios();

  const handleDownload = async (href: string, name: string) => {
    const { data } = await api.get(href, {
      responseType: 'blob',
    })

    if (data) {
      fileDownload(data, name)
      setViewModal(false)
      setUrl(null)
    }

  }

  const [extension, setExtension] = useState("pdf")


  useEffect(() => {
    if (url && url.substring(url.lastIndexOf('.') + 1)) {
      let ext = url.substring(url.lastIndexOf('.') + 1)
      setExtension(ext)
      if (!extensionsSupported.includes(ext.toLowerCase()) &&
      !url.includes("blob:")) {
        toast.warning("Format de fichier non supporté", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleDownload(url, new URL(url).pathname.split('/').pop() || "")
      }
    }

  }, [url])


  return (
    <>
      {
        extension && !extensionsSupported.includes(extension.toLowerCase()) ? <></> : <Modal
          isOpen={viewModal}
          toggle={() => {
            setViewModal(false);
            setUrl(null);
          }}
          className={`${url?.includes(".doc") ? "with_html" : ""}`}
        >
          <ModalHeader
            toggle={() => {
              setViewModal(false);
              setUrl(null);
            }}
          >
            Prévisualisation du fichier
          </ModalHeader>
          <ModalBody>
            <DocView url={url} hasWaterMark={hasWaterMark} fileType={fileType} />
          </ModalBody>
        </Modal>
      }
    </>

  );
};

export default FileViewer;

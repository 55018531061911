import React, { useEffect, useState } from "react";
import "./ImmoblisationArchive.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { BootyPagination } from "../../../components/table/pagination";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import { ICategory, ISubCategory, ITransaction } from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import axios from "axios";
import moment from "moment";
import {
  setCounter,
  setErrorMessage,
} from "../../../store/reducers/utils/utilSlice";
import {
  RiArchiveFill,
  RiDownload2Fill,
  RiErrorWarningLine,
  RiSearchLine,
  RiTimeFill,
} from "react-icons/ri";
import tvaEditIcon from "../../../assets/images/svg/edit-small-blue.svg";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import FileViewer from "../../../components/FileViewer";
import { number } from "yup/lib/locale";
import ArchiveIcon from "../../../assets/ArchiveIcon";
import {
  ASSETS_OPTIONS,
  ASSETS_OPTIONS_VALIDATORS,
  OptionType,
  UserTypes,
} from "../../../util/context";
import MoneySacIconBlue from "../../../assets/images/svg/money-sac-blue.svg";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import { useNavigate } from "react-router-dom";
import ZoomIcon from "../../../assets/ZoomIcon";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

type SearchFormValues = {
  dateFrom: string | null;
  dateTo: string | null;
  numberOfYears: string | null;
  activityCode: string | null;
};

type ImmobFormValues = {
  type: OptionType | null;
  numberOfYears: number | null;
  transactionName: string | null;
  transactionDate: string | null;
  transactionAmount: number | null;
};

const ImmoblisationArchive = ({ context }: { context?: string }) => {
  const [open, setOpen] = useState(false);
  const [immbModal, setImmobModal] = useState<boolean>(false);
  const [openCategModal, setOpenCategModal] = useState<boolean>(false);
  const [categStatus, setCategStatus] = useState<string | null>();
  const [categRemb, setCategremb] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const [decCategs, setDecCategs] = useState<ICategory[]>([]);
  const [encCategs, setEncCategs] = useState<ICategory[]>([]);
  const [singleTrans, setSingleTrans] = useState<ITransaction | null>();
  const [singleImmob, setImmob] = useState<ITransaction | null>();
  const [singleTVA, setSingleTVA] = useState<string | null>();
  const [file, setFile] = useState("");
  const [transactionId, setTransactionId] = useState<string | null>();
  const { setDecimalDigits } = useFormatter();
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };
  let api = useAxios();

  const [pending, setPending] = useState<boolean>(true);

  const columns: any = React.useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: { date: Date }) => moment(row.date).format("DD/MM/YYYY"),
      },
      {
        name: "Durée d'ammortissement",
        selector: (row: any) =>
          `${row.numberOfYears} ${row.numberOfYears > 1 ? "ans" : "an"}`,
        sortable: true,
      },
      {
        name: "Compte utilisé",
        selector: (row: ITransaction) => row.sub_category.activityCode,
        sortable: true,
        omit: creds.role === UserTypes.Client,
      },
      {
        name: "Compte proposé",
        selector: (row: ITransaction) =>
          row.sub_category.activityCode.replace("2", "28"),
        sortable: true,
        omit: creds.role === UserTypes.Client,
      },
      {
        name: `Ammortissement total`,
        sortable: true,
        cell: (row: ITransaction) => {
          const amount = row.immobilisations?.find(
            (elt) => moment(elt.date).get("year") === new Date().getFullYear()
          )?.amount;
          return <span>{amount ? `${setDecimalDigits(amount)} €` : "-"}</span>;
        },
      },
    ],
    [creds.role]
  );

  const [currentImmoblisations, setArchivedImmobilisations] = useState<
    ITransaction[]
  >([]);

  const getImmoblisations = async () => {
    if (!context) {
      return;
    }
    try {
      const { data } = await api.post(
        `/api/Transaction/All`,
        {
          where: {
            status: 102,
            companyId: context,
            relatedToFactureType: {
              or: [null, "child"],
            },
            sub_category: {
              category: {
                name: "Immobilisations",
              },
            },
            date: {
              lte: moment(new Date()).format("YYYY-MM-DD hh:mm"),
            },
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.data.length > 0) {
        let filtred: ITransaction[] = [];
        for (let imm of data.data as ITransaction[]) {
          if (
            moment(imm.date).add(Number(imm.numberOfYears), "years") <=
            moment(new Date()).startOf("year")
          ) {
            filtred.push(imm);
          }
        }
        setArchivedImmobilisations(filtred);
      } else {
        setArchivedImmobilisations([]);
      }
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting archived immoblisations", error);
    }
  };

  const searchImmobs: SubmitHandler<SearchFormValues> = async (
    form: SearchFormValues
  ) => {
    if (!context) {
      return;
    }
    try {
      setPending(true);
      let payload: any = {
        companyId: context,
        status: 101,
        relatedToFactureType: {
          or: [null, "child"],
        },
        sub_category: {
          category: {
            name: "Immobilisations",
          },
        },
      };
      if (form.dateFrom || form.dateTo)
        payload.date = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };
      if (form.numberOfYears)
        payload.numberOfYears = Number(form.numberOfYears);
      if (form.activityCode) {
        payload = {
          ...payload,
          sub_category: {
            activityCode: form.activityCode,
          },
        };
      }

      const { data } = await api.post(
        `/api/Transaction/all`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setArchivedImmobilisations(data.data);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("searching emails in mailing", error);
    }
  };

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchFormValues>({});

  // search email form
  const { ref: dateToRef, ...dateTo } = register("dateTo");
  const { ref: dateFromRef, ...dateFrom } = register("dateFrom");
  const { ref: numberOfYearsRef, ...numberOfYears } = register("numberOfYears");
  const { ref: activityCodeRef, ...activityCode } = register("activityCode");

  useEffect(() => {
    getImmoblisations();
  }, []);

  return (
    <Card className="card-Table table-secondary custom-card">
      <div className="search-top custom_search-top">
        <form onSubmit={handleSubmit(searchImmobs)}>
          <Row className="align-items-end">
            <Col md={8}>
              <div className="filterInner">
                <Row>
                  <Col md={3}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dated">Date de début</Label>
                      <Controller
                        control={control}
                        name="dateFrom"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="dated"
                        {...dateFrom}
                        innerRef={dateFromRef}
                        placeholder="Date de début"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary icon-secondary-fill">
                        {/* <img src={CalenderIconBlue} alt="icon" /> */}
                        {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="datef">Date de fin</Label>
                      <Controller
                        control={control}
                        name="dateTo"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="datef"
                        {...dateTo}
                        innerRef={dateToRef}
                        placeholder="Date de fin"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary icon-secondary-fill">
                        {/* <img src={CalenderIconBlue} alt="icon" /> */}
                        {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="numberOfYears">Durée</Label>
                      <Input
                        id="numberOfYears"
                        {...numberOfYears}
                        innerRef={numberOfYearsRef}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary"
                        min={0}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <ZoomIcon />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="activityCode">Numéro de compte</Label>
                      <Input
                        id="activityCode"
                        {...activityCode}
                        innerRef={activityCodeRef}
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <ZoomIcon />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={async () => {
                    reset({
                      dateFrom: null,
                      dateTo: null,
                      numberOfYears: null,
                      activityCode: null,
                    });
                    await getImmoblisations();
                  }}
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <DataTable
        columns={columns}
        data={currentImmoblisations}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        paginationComponent={(props) => {
          const customProps = { ...props, color: "secondary" };
          return <BootyPagination {...customProps} />;
        }}
        progressPending={pending}
        progressComponent={
          <>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
            <Spinner color="secondary" type="grow" className="mx-1">
              Loading...
            </Spinner>
          </>
        }
      />
      <div className="openbtn text-center"></div>
    </Card>
  );
};

export default ImmoblisationArchive;

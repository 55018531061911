import React, { useState, FormEvent, useEffect } from "react";

import "./style.scss";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { RiCalendar2Line } from "react-icons/ri";
import Tabs from "../../components/Tabs";
import SignIn from "../Account/signin/signin";
import Register from "../Account/register/register";
import imgLogin from "../../assets/images/img-auth.png";
import UserChat from "../../assets/images/avatar_empty.png";
import iconsTransaction from "../../assets/images/icons/managemen_uang.png";
import iconsInfoClient from "../../assets/images/icons/infos-img.png";
import iconsHome from "../../assets/images/icons/3d-house.png";
import iconsImmobilsation from "../../assets/images/icons/immobilsation.png";
import iconsVat from "../../assets/images/icons/vat.png";
import iconsCar from "../../assets/images/icons/electric_car_batter.png";
import iconsBoard from "../../assets/images/icons/presentation_board_9.png";
import iconsBilan from "../../assets/images/icons/bilan-icon.png";
import iconsInvoice from "../../assets/images/icons/payment_invoice.png";
import iconsQuittance from "../../assets/images/icons/quitance-bo.png";
import iconsInvoiceImport from "../../assets/images/icons/invoice-import.png";
import iconsDollar from "../../assets/images/icons/invoice_dollar.png";
import logoCreneauaceri from "../../assets/images/logos/logo_creneauaceri.png";
import arrowIconLogo from "../../assets/images/icons/arrow-iconn.png";
import MesTransactions from "../MesTransactions/MesTransactions";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ClientInfo from "../ClientInfo";
import TopNav from "../../components/topnav/TopNav";
import { useDispatch, useSelector } from "react-redux";
import { Company, User } from "../../interfaces";
import config from "../../config";
import axios from "axios";
import { ErrorLogger } from "../../util/errorLogger";
import Indemnity from "../Indemnity";
import OdTva from "../OdTva/OdTva";
import TransactionsImport from "../TransactionsImport";
import CompanyInfo from "../CompanyInfo";
import Immobilisation from "../Immobilisation";
import Facturation from "../Facturation";
import Bilan from "../Bilan/Bilan";
import Dashboard from "../Dashboard/Dashboard";
import { AppPacks, NonImmoPacks, UserTypes } from "../../util/context";
import { toast, ToastContainer } from "react-toastify";
import { resetApplications } from "../../store/reducers/applications/applicationsSlice";
import { resetCategories } from "../../store/reducers/categories/categoriesSlice";
import { resetClientsList } from "../../store/reducers/clients-list/clientsSlice";
import { resetCompaniesList } from "../../store/reducers/companies-list/companiesSlice";
import { resetPermissionsList } from "../../store/reducers/permissions-list/permissionsListSlice";
import { resetUser } from "../../store/reducers/user/userSlice";
import { persistor } from "../../store";
import {
  resetUtil,
  setCounter,
  setReloadTickets,
} from "../../store/reducers/utils/utilSlice";
import useAxios from "../../util/hooks/useAxios";
import { resetNotifs } from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import { deleteWhere } from "../../util/helpers/utilities";
import { resetRoot } from "../../store/reducers/root/rootSlice";
import FacturationImport from "../FacturationImport";
import Quitance from "../Quittance";

export interface TransactionsProps {}

const bicPlusCheck = (company: Company, pack: AppPacks) => {
  return (
    [
      AppPacks.BICPlus.toLowerCase(),
      AppPacks.BilanBICPlus.toLowerCase(),
    ].includes(company?.pack.toLowerCase() as any) && !company.subjectToVAT
  );
};

type TabsType = {
  label: string;
  image?: string;
  index: number;
  Component: React.FC<{}>;
  blackList: string[];
  stateBlackList?: any;
}[];

type LocationStateDefined = {
  parent: number;
  child: number;
};

export type LocationState = LocationStateDefined | null;

// Tabs Array
const tabs: TabsType = [
  {
    label: "Transactions",
    image: iconsTransaction,
    index: 1,
    Component: MesTransactions,
    blackList: [],
  },
  {
    label: "Informations client",
    image: iconsInfoClient,
    index: 2,
    Component: ClientInfo,
    blackList: [],
  },
  {
    label: "Informations société",
    image: iconsHome,
    index: 3,
    Component: CompanyInfo,
    blackList: [],
  },
  {
    label: "Immobilisations",
    image: iconsImmobilsation,
    index: 4,
    Component: Immobilisation,
    blackList: [AppPacks.Entrepreneur, AppPacks.BilanEntrepreneur],
  },
  // {
  //   label: "OD TVA",
  //   image: iconsVat,
  //   index: 5,
  //   Component: OdTva,
  //   blackList: [AppPacks.Entrepreneur, AppPacks.BNCAssoc],
  //   stateBlackList: bicPlusCheck,
  // },
  {
    label: "Indemnité kilométrique",
    image: iconsCar,
    index: 5,
    Component: Indemnity,
    blackList: [
      AppPacks.Entrepreneur,
      AppPacks.Immo,
      AppPacks.ImmoPlus,
      AppPacks.ImmoPlusTVA,
      AppPacks.ImmoTVA,

      AppPacks.BilanEntrepreneur,
      AppPacks.BilanImmo,
      AppPacks.BilanImmoPlus,
      AppPacks.BilanImmoPlusTVA,
      AppPacks.BilanImmoTVA,
    ],
  },
  {
    label: "Tableau de board",
    image: iconsBoard,
    index: 6,
    Component: Dashboard,
    blackList: [],
  },
  {
    label: "Bilan",
    image: iconsBilan,
    index: 7,
    Component: Bilan,
    blackList: [AppPacks.Entrepreneur, AppPacks.BilanEntrepreneur],
  },
  {
    label: "Facturation",
    image: iconsInvoice,
    index: 8,
    Component: Facturation,
    blackList: [
      AppPacks.Immo,
      AppPacks.ImmoPlus,
      AppPacks.ImmoPlusTVA,
      AppPacks.ImmoTVA,

      AppPacks.BilanImmo,
      AppPacks.BilanImmoPlus,
      AppPacks.BilanImmoPlusTVA,
      AppPacks.BilanImmoTVA,
    ],
  },
  {
    label: "Édition de quittance",
    image: iconsQuittance,
    index: 9,
    Component: Quitance,
    blackList: [...NonImmoPacks],
  },
  {
    label: "Import Facture",
    image: iconsInvoiceImport,
    index: 10,
    Component: FacturationImport,
    blackList: [
      AppPacks.Entrepreneur,
      AppPacks.BIC,
      AppPacks.BNCAssoc,
      AppPacks.BNCAssocPlus,
      AppPacks.Immo,
      AppPacks.ImmoPlus,
      AppPacks.ImmoPlusTVA,
      AppPacks.ImmoTVA,

      AppPacks.BilanEntrepreneur,
      AppPacks.BilanBIC,
      AppPacks.BilanBNCAssoc,
      AppPacks.BilanBNCAssocPlus,
      AppPacks.BilanImmo,
      AppPacks.BilanImmoPlus,
      AppPacks.BilanImmoPlusTVA,
      AppPacks.BilanImmoTVA,
    ],
  },
  {
    label: "Import",
    image: iconsDollar,
    index: 11,
    Component: TransactionsImport,
    blackList: [],
  },
];

const ClientBackOffice = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentCompany, setCurrentCompany] = useState<Company | null>();
  const [reloadClientCompany, setReloadClinetCompany] =
    useState<boolean>(false);
  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { id } = useParams();
  const navigate = useNavigate();
  let { state: locationState } = useLocation();

  let api = useAxios();

  const getUser = async () => {
    try {
      setReloadClinetCompany(false);
      const { data } = await api.post(
        `/api/Company/All`,
        {
          where: {
            id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setCurrentUser(data.data[0].client);
    } catch (error: any) {
      ErrorLogger("getting client info", error);
    }
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = UserChat;
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getUser();
    setCurrentCompany(companiesList.find((elt) => elt.id === id) || null);
  }, [companiesList, reloadClientCompany]);

  //handle navigation for admin with tab states
  const [tempState, setTempState] = useState<any>(null);
  useEffect(() => {
    const state = tempState as LocationState;
    if (
      creds.role !== UserTypes.Client &&
      state &&
      state.parent !== selectedTab &&
      (!locationState || (locationState as any).action !== "suivi-tva")
    ) {
      setSelectedTab(state.parent);
    }
    if (
      creds.role !== UserTypes.Client &&
      locationState &&
      (locationState as any).action === "suivi-tva"
    ) {
      setSelectedTab(5);
    }
  }, [tempState]);

  return (
    <>
      <div className="main-User-client ">
        <div className="header">
          <div className="container-full">
            <div className="inner-header">
              <div className="left-header">
                <button
                  className="retour-btn btn btn-primary"
                  onClick={() => navigate(-1)}
                >
                  <img src={arrowIconLogo} alt="icon" /> <span>Retour</span>
                </button>
                <div className="logo-client">
                  <span className="img-logo">
                    <img
                      src={
                        currentUser?.profilePhoto
                          ? currentUser?.profilePhoto.url
                          : UserChat
                      }
                      alt="icon"
                      onError={addDefaultSrc}
                    />
                  </span>
                  <span className="label-logo">
                    {companiesList.find((elt) => elt.id === id)?.name ||
                      companiesList.find((elt) => elt.id === id)?.email}
                  </span>
                </div>
              </div>
              <div className="right-header">
                <TopNav />
              </div>
            </div>
          </div>
        </div>
        <div className="container-full">
          <div className="client-wrrap">
            <Tabs
              selectedTab={selectedTab}
              onClick={(index: number) => {
                // if (index !== selectedTab && searchParams.get("action")) {
                //   searchParams.delete("action");
                // }
                setSelectedTab(index);
              }}
              tabs={tabs.filter((elt) => {
                let isAllowedTab = !elt.blackList.includes(
                  currentCompany?.pack!
                );
                if (
                  elt.stateBlackList &&
                  currentCompany?.pack !== AppPacks.Entrepreneur
                ) {
                  isAllowedTab = !elt.stateBlackList(
                    currentCompany,
                    currentCompany?.pack!
                  );
                }
                return isAllowedTab;
              })}
              context={id}
              pack={currentCompany?.pack}
              company={currentCompany!}
              client={currentUser}
              callback={setReloadClinetCompany}
              reload={reloadClientCompany}
              tempState={tempState}
              setTempState={setTempState}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientBackOffice;

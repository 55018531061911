import React, { useEffect, useState } from "react";
import "./IbanTelepaiement.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import juridiqueIcons from "../../../assets/images/svg/juridique-icon.svg";
import bankIcons from "../../../assets/images/svg/bank-blue.svg";
import { Company, User } from "../../../interfaces";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorLogger } from "../../../util/errorLogger";
import { fetchAllCompanies } from "../../../store/reducers/companies-list/companiesSlice";
import axios from "axios";
import config from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import useAxios from "../../../util/hooks/useAxios";
import { UserTypes } from "../../../util/context";
import { ReactSVG } from "react-svg";
export interface AvaliderProps {}

type GeneralFormValues = Partial<Company>;

const { API_URL } = config[process.env.NODE_ENV];

const IbanTelepaiement = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {

  const [loading, setLoading] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string;role : string };

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  useEffect(() => {
    reset({
      ibanPayment: company?.ibanPayment,
      iban: company?.iban,
      bic: company?.bic,
      acceptIbanPayment: company?.acceptIbanPayment,
    });
  }, [company]);

  const { ref: ibanPaymentRef, ...ibanPayment } = register("ibanPayment");
  const { ref: ibanRef, ...iban } = register("iban");
  const { ref: bicRef, ...bic } = register("bic");
  const { ref: acceptIbanPaymentRef, ...acceptIbanPayment } =
    register("acceptIbanPayment");

  let api = useAxios();

  const callbackWrapper = async () => {
    if (creds.role !== UserTypes.Client) {
      return dispatch(fetchAllCompanies());
    } else {
      await callback!();
    }
  };

  const updateCompany: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (creds.role !== UserTypes.Client) {
        formData.append("id", id as string);
      }else {
        formData.append("id", creds.company_id as string);
      }
      

      if (String(form.ibanPayment) !== String(company?.ibanPayment)) {
        formData.append("ibanPayment", String(form.ibanPayment));
      }

      if (String(form.iban) !== String(company?.iban)) {
        formData.append("iban", String(form.iban));
      }

      if (String(form.bic) !== String(company?.bic)) {
        formData.append("bic", String(form.bic));
      }

      if (
        String(form.acceptIbanPayment) !==
        String(company?.acceptIbanPayment)
      ) {
        formData.append("acceptIbanPayment", String(form.acceptIbanPayment));
      }

      await api.post(`/api/Company/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await callbackWrapper();
      setLoading(false);
      toast.dismiss()
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  return (
    <>
      
      <div className="container">
        <form onSubmit={handleSubmit(updateCompany)}>
          <div className="section-Iban-paie">
            <h3>IBAN DE TÉLÉPAIEMENT</h3>
            <Row>
              <Col md={12} className="mb-5">
                <div className="check-box check-secondary">
                  {getValues("acceptIbanPayment") !== undefined ? (
                    <input
                      className="styled-checkbox"
                      id="checkbox-3"
                      type="checkbox"
                      ref={acceptIbanPaymentRef}
                      {...acceptIbanPayment}
                      defaultChecked={getValues("acceptIbanPayment") === true}
                    />
                  ) : (
                    <Spinner color="info" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  )}

                  <label htmlFor="checkbox-3">
                    Je souhaiterais que Noly Compta se charge des déclarations
                    comptables sur <a href="https://www.impots.gouv.fr/accueil" target={"_blank"}>impots.gouv.fr.</a>
                  </label>
                </div>
              </Col>
              <Col md={4} sm={12} className="pe-md-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="ibantee">IBAN de Télépaiement</Label>
                  <Input
                    id="ibantee"
                    innerRef={ibanPaymentRef}
                    {...ibanPayment}
                    type="text"
                    className="form-secondary"
                  />
                  <span className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={bankIcons} alt="icon" /> */}
                    {<ReactSVG src={bankIcons} />}
                  </span>
                </FormGroup>
              </Col>
              <Col md={4} sm={12} className="px-md-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="nc">IBAN de paiement éléctronique</Label>
                  <Input
                    id="ibante"
                    innerRef={ibanRef}
                    {...iban}
                    type="text"
                    className="form-secondary"
                  />
                  <span className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={bankIcons} alt="icon" /> */}
                    {<ReactSVG src={bankIcons} />}
                  </span>
                </FormGroup>
              </Col>
              <Col md={4} sm={12} className="ps-md-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="nc">BIC</Label>
                  <Input
                    id="bic"
                    innerRef={bicRef}
                    {...bic}
                    type="text"
                    className="form-secondary"
                  />
                  <span className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={bankIcons} alt="icon" /> */}
                    {<ReactSVG src={bankIcons} />}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <div className="my-5 text-nb">
              <p>
                Ce compte bancaire sera utilisé sur votre espace professionnel
                <br />
                <a
                  className="link"
                  href="https://www.impots.gouv.fr/"
                  target={"_blank"}
                >
                  impots.gouv.fr
                </a>
                . Un mandat devra être transmis à votre banque pour permettre
                les télé-paiements.
              </p>
            </div>
            <div className="text-center py-3 bottom-actions">
              {/* <Button
                color="secondary"
                outline
                onClick={function noRefCheck() {}}
              >
                Reinitialiser
              </Button> */}
              <Button color="secondary" type="submit" disabled={loading}>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Enregistrer"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default IbanTelepaiement;

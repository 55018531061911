import React, {ReactNode, useEffect, useMemo, useState} from "react";
import "./CompteSynchronise.scss";
import juridiqueIcons from "../../../assets/images/svg/juridique-icon.svg";
import KeyIcon from "../../../assets/images/svg/key-blue.svg";
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Spinner,
  ButtonGroup,
} from "reactstrap";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
  Company,
  IBridgeAccount,
  IBridgeItem,
  ISyncOrg,
  User,
} from "../../../interfaces";
import {ErrorLogger} from "../../../util/errorLogger";
import {SubmitHandler, useForm} from "react-hook-form";
import {UserTypes} from "../../../util/context";

import PlusShadow from "../../../assets/PlusShadow";
import DeletShadow from "../../../assets/DeletShadow";
import useAxios from "../../../util/hooks/useAxios";

import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";

import "moment/locale/fr";

import {BsPlusCircleDotted} from "react-icons/bs";
import {AiOutlineEdit} from "react-icons/ai";
import GestionCompte from "./CompteSynchroniseModal/GestionCompte";
import CompteGestionIbanModal from "./CompteGestionIbanModal/CompteGestionIbanModal";
import {ReactSVG} from "react-svg";
import {getClient} from "../../../api/client";
registerLocale("fr", fr);

export interface AvaliderProps {}
type SyncOrgFormValues = Omit<Partial<ISyncOrg>, "name" | "login" | "key"> & {
  name: string | null;
  login: string | null;
  key: string | null;
};

export type CheckItem = {
  id: string | number;
  status: string | number;
  status_code_info?: string | number | null;
  status_code_description?: string | number | null;
  bank_id?: string | number | null;
};

export type RedirectUrls = {
  item: string | number;
  url: string;
};

const CompteSynchronise = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [syncOrgs, setSyncorgs] = useState<ISyncOrg[]>([]);
  const [bridegItems, setBridgeItems] = useState<IBridgeItem[]>([]);

  const [proValidationItems, setValidationItems] = useState<CheckItem[]>([]);
  const [otpItems, setOtpItems] = useState<CheckItem[]>([]);
  const [otherItems, setOtherItems] = useState<CheckItem[]>([]);
  const [stopSyncModal, setStopSyncModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [addSyncOrgLoading, setAddSyncOrgLoading] = useState<boolean>(false);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [choiceModal, setChoiceModal] = useState<boolean>(false);
  const [syncModal, setSyncModal] = useState<boolean>(false);
  const [gestionModal, setGestionModal] = useState(false);
  const [singleSyncOrg, setSingleSyncOrg] = useState<ISyncOrg | null>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientId, setClientId] = useState("");

  const [multiLoading, setMultiLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    application_id: string;
  };

  const {id} = useParams();
  const {userCompanies} = useSelector(
    (state: {root: object; user: object}) => state.user
  ) as {
    userCompanies: Company[];
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<SyncOrgFormValues>({});

  const {ref: nameRef, ...name} = register("name");
  const {ref: loginRef, ...login} = register("login");
  const {ref: keyRef, ...key} = register("key");

  const [isQontoUser, setIsQontoUser] = useState<boolean>(false);
  const [openIbanModal, setOpenIbanModal] = useState(false);

  let api = useAxios();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const findClient = async () => {
    try {
      const {data} = await getClient({
        companyId: id ? id : "",
        api,
      });
      setClientId(data.data.id);
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      findClient();
    }
  }, [id]);

  const getSyncorgs = async () => {
    try {
      setLoading(true);
      const {data} = await api.post(
        `/api/SyncOrganization/all`,
        {
          where: {
            companyId: creds.role !== UserTypes.Client ? id : creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSyncorgs(data.data);
    } catch (error: any) {
      ErrorLogger("getting notes", error);
    } finally {
      setLoading(false);
    }
  };

  const getBridgeItems = async () => {
    try {
      setLoading(true);
      const {data} = await api.post(
        `/api/v2/BridgeItem/All`,
        {
          where: {
            clientId: userCompanies[0]?.client_id ?? clientId ?? "",
          },
          perPage: 5,
          pageIndex: currentPage,
          order: [
            ["createdAt", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setTotalRows(data.count);
      const hasQontoBank = data.data.findIndex(
        (elt: any) => (elt.bankId = 421)
      );

      setBridgeItems(data.data);
      if (hasQontoBank > -1) {
        setIsQontoUser(true);
      }
    } catch (error: any) {
      ErrorLogger("getting bridge items", error);
    } finally {
      setLoading(false);
    }
  };

  const firstSync = async () => {
    try {
      setFirstLoading(true);
      setErrorMessage(null);
      const {data} = await api.post(
        `/api/v2/accountSync/createRedirectURL`,
        {
          companyId: creds.role === UserTypes.Client ? creds.company_id : id,
          syncType: "new",
          from: creds.application_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.error) {
        setFirstLoading(false);
        setErrorMessage({
          type: "first_sync_error",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }
      window.location = data.url;
      setFirstLoading(false);
      setSyncModal(false);
    } catch (error: any) {
      ErrorLogger("creating redirect url", error);
      setFirstLoading(false);
      setErrorMessage({
        type: "first_sync_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  useEffect(() => {
    if (creds.company_id) {
      getSyncorgs();
      // getBridgeItems();
    }
  }, [creds.company_id]);

  useEffect(() => {
    if (userCompanies[0]?.client_id || clientId) {
      getBridgeItems();
    }
  }, [currentPage, userCompanies, clientId]);

  const addSyncOrg: SubmitHandler<SyncOrgFormValues> = async (
    form: SyncOrgFormValues
  ) => {
    try {
      if (!form.login || !form.name || !form.key) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données ",
        });
        return;
      }
      setAddSyncOrgLoading(true);
      await api.post(
        `/api/SyncOrganization/Create`,
        {
          name: form.name,
          login: form.login,
          key: form.key,
          company_id: creds.role !== UserTypes.Client ? id : creds.company_id,
          bankId: 421,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getSyncorgs();
      reset({
        name: null,
        login: null,
        key: null,
      });
      setAddSyncOrgLoading(false);
    } catch (error: any) {
      ErrorLogger("creating sync org", error);
      setAddSyncOrgLoading(false);
    }
  };

  const deleteSyncOrg = async () => {
    try {
      setLoading(true);
      await api.post(
        `/api/SyncOrganization/Delete`,
        {
          id: singleSyncOrg?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getSyncorgs();
      setDeleteModal(false);
      setSingleSyncOrg(null);
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    } finally {
      setLoading(false);
    }
  };

  const handelModal = () => {
    return setOpenIbanModal((prevValue) => {
      return !prevValue;
    });
  };

  return (
    <>
      <div className="container">
        <div className="section-Compte-sync">
          <h3
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "5px",
              alignItems: "center",
            }}
          >
            Gestion du compte bancaire
            <div style={{display: "flex", gap: "5px"}}>
              {/* {ibans.length > 0 ? (
                <Button
                  color="secondary"
                  outline
                  type="submit"
                  disabled={firstLoading}
                  onClick={handelModal}
                  className={"headerBtn"}
                >
                  <AiOutlineEdit
                    className="headerIcon"
                    color={"blue"}
                    width={"50px"}
                  />
                  <p className="headerText">Gestion Iban</p>
                </Button>
              ) : null} */}
              <Button
                color="secondary"
                outline
                type="submit"
                disabled={firstLoading}
                onClick={async () => {
                  await firstSync();
                }}
                className={"headerBtn"}
              >
                <BsPlusCircleDotted
                  className="headerIcon"
                  color={"blue"}
                  width={"50px"}
                />
                <p className="headerText">Ajouter un compte bancaire</p>
              </Button>
            </div>
          </h3>

          {/* gestion compte */}
          <GestionCompte
            bridegItems={bridegItems}
            setGestionModal={setGestionModal}
            gestionModal={gestionModal}
            getBridgeAcoounts={getBridgeItems}
            setStopSyncModal={setStopSyncModal}
            creds={creds}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            loading={loading}
            clientId={userCompanies[0]?.client_id ?? ""}
          />
          <h3>Gestion de compte Qonto</h3>
          {(isQontoUser || syncOrgs.length > 0) && (
            <>
              <div className="blocCode">
                <Row>
                  {syncOrgs.length > 0 &&
                    syncOrgs.map((elt, index) => (
                      <React.Fragment key={index}>
                        <Col md={3} className="px-md-3">
                          <FormGroup className="form-icon icon-start">
                            <Label for="namecamp">Nom de l'organisation</Label>
                            <Input
                              id="namecamp"
                              name="namecamp"
                              placeholder="Nom de  l'organisation"
                              type="text"
                              className="form-secondary"
                              disabled={true}
                              value={elt.name}
                            />
                            <span className="icon icon-secondary ">
                              {/* <img src={juridiqueIcons} alt="icon" /> */}
                              {<ReactSVG src={juridiqueIcons} />}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3} className="px-md-3">
                          <FormGroup className="form-icon icon-start">
                            <Label for="nexc">identifiant</Label>
                            <Input
                              id="nexc"
                              name="nexc"
                              placeholder="identifiant"
                              type="text"
                              className="form-secondary"
                              disabled={true}
                              value={elt.login}
                            />
                            <span className="icon icon-secondary ">
                              {<ReactSVG src={juridiqueIcons} />}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3} className="px-md-3">
                          <FormGroup className="form-icon icon-start">
                            <Label for="phone">Clé secrete</Label>
                            <Input
                              id="phone"
                              name="phone"
                              placeholder="Clé secrète"
                              type="text"
                              className="form-secondary"
                              disabled={true}
                              value={elt.key}
                            />
                            <span className="icon icon-secondary ">
                              {<ReactSVG src={KeyIcon} />}
                              {/* <img src={KeyIcon} alt="icon" /> */}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3} className="px-md-3 delete-sync-org-button">
                          <Button
                            color="danger"
                            outline
                            onClick={() => {
                              setSingleSyncOrg(elt);
                              setDeleteModal(true);
                            }}
                          >
                            <DeletShadow />{" "}
                            <span className="delet-span">Supprimer</span>
                          </Button>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
                <form onSubmit={handleSubmit(addSyncOrg)}>
                  <Row>
                    <Col md={4} sm={6} className="pe-md-4">
                      <FormGroup className="form-icon icon-start">
                        <Label for="namecamp">Nom de l'organisation</Label>
                        <Input
                          id="namecamp"
                          innerRef={nameRef}
                          {...name}
                          placeholder="Nom de  l'organisation"
                          type="text"
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={juridiqueIcons} />}
                          {/* <img src={juridiqueIcons} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="px-md-4">
                      <FormGroup className="form-icon icon-start">
                        <Label for="nexc">identifiant</Label>
                        <Input
                          id="nexc"
                          innerRef={loginRef}
                          {...login}
                          placeholder="identifiant"
                          type="text"
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={juridiqueIcons} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="px-md-4">
                      <FormGroup className="form-icon icon-start">
                        <Label for="phone">Clé secrete</Label>
                        <Input
                          id="phone"
                          innerRef={keyRef}
                          {...key}
                          placeholder="Clé secrète"
                          type="text"
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={juridiqueIcons} />}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                  <div className="mt-3 bottom-actions">
                    <Button
                      color="secondary"
                      type="submit"
                      className="icon-left"
                    >
                      <span className="icon icon-secondary">
                        <PlusShadow />
                      </span>
                      {!addSyncOrgLoading ? (
                        <span className="sync-add-span">Ajouter</span>
                      ) : (
                        <Spinner color="light" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      )}
                    </Button>
                  </div>
                </form>
              </div>
              <div className="my-4 text-nb">
                <p>
                  <strong>
                    NB : Après avoir été connecté à votre compte Qonto :
                  </strong>
                  <br /> 1 - Choisissez votre organisation
                  <br />2 - Cliquez sur "Paramètres & Équipe"
                  <br />3 - Cliquez sur "Clé API"
                  <br /> 4 - Copiez et collez l'identifiant et la clé secrète
                  dans les entrées correspondantes de votre application Noly.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="openbtn text-center">
        {/* delete sync org */}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteModal}
          toggle={() => {
            setSingleSyncOrg(null);
            setDeleteModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleSyncOrg(null);
              setDeleteModal(false);
            }}
          >
            Supprimer une organisation
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer cette organisation{" "}
                {singleSyncOrg?.name} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              type="button"
              onClick={() => {
                setSingleSyncOrg(null);
                setDeleteModal(false);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              type="submit"
              onClick={async () => await deleteSyncOrg()}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>

        {/* select sync type */}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={syncModal}
          toggle={() => {
            setSyncModal(false);
            setErrorMessage(null);
            setFirstLoading(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSyncModal(false);
              setErrorMessage(null);
              setFirstLoading(false);
            }}
          >
            Confirmer la synchronisation
          </ModalHeader>
          <ModalBody>
            <div className="sync-modal">
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    setFirstLoading(false);
                    setErrorMessage(null);
                    setChoiceModal(true);
                  }}
                >
                  Comptes bancaires déjà synchronisées
                </Button>
              </div>
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="submit"
                  disabled={firstLoading}
                  onClick={async () => {
                    await firstSync();
                  }}
                >
                  Nouveau Compte bancaire
                </Button>
              </div>
            </div>
            {firstLoading && (
              <div className="d-flex justify-content-center text-info mt-2">
                Création d'un lien de synchronisation, veuillez patienter.
              </div>
            )}
            {errorMessage?.type === "first_sync_error" && (
              <div className="d-flex justify-content-center text-danger mt-2">
                {errorMessage?.message}
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default CompteSynchronise;

import React, { useEffect, useState } from "react";
import "./ListeDevis.scss";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import { BootyPagination } from "../../../components/table/pagination";
import { RiSearchLine } from "react-icons/ri";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import Select, { SingleValue } from "react-select";
import ReviewIcon from "../../../assets/ReviewIcon";
import FactureHtml from "../FactureHtml/FactureHtml";
import FactureRev from "../FactureRev/FactureRev";
import config from "../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Company,
  IDevis,
  IItem,
  ISubCategory,
  User,
} from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import moment, { Moment } from "moment";
import {
  QUOTE_OPTIONS,
  OptionType,
  ClientTypes,
  UserTypes,
} from "../../../util/context";
import Status from "../../../components/badge/Status";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { IoMdCreate } from "react-icons/io";
import EditFacture, { GeneralFormValues } from "../EditFacture/EditFacture";
import { toast, ToastContainer } from "react-toastify";
import fileSaver from "file-saver";
import SelectableBadge from "../../../components/SelectableBadge";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

type DynamicObj = {
  [prop: string]: boolean;
};

export interface FactProps {}
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

type SearchDevissFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  company?: OptionType | null;
  status?: OptionType | null;
};

const { API_URL } = config[process.env.NODE_ENV];

const ListeDevis = ({
  client,
  context,
  company,
  callback,
  reloadParent,
  reload,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: any;
  reload?: boolean;
  reloadParent?: any;
}) => {
  const [sendDevisLoader, setSendDevisLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deviss, setDeviss] = useState<IDevis[]>([]);
  const [singleDevis, setSingleDevis] = useState<IDevis | null>();
  const [loading, setLoading] = useState(false);
  const [openDevisModal, setOpenDevisModal] = useState<boolean>(false);
  const [deleteDevisModal, setDeleteDevisModal] = useState<boolean>(false);
  const [editDevisModal, setEditDevisModal] = useState<boolean>(false);
  const [sendDevisModal, setSendDevisModal] = useState<boolean>(false);
  const [emailSendError, setEmailSendError] = useState<boolean>(false);

  const [needEmail, setNeedEmail] = useState<boolean>(false);
  const [needModelRef, setNeedModelRef] = useState<boolean>(false);

  const [saveAsModelLoading, setSaveAsModelLoading] = useState<boolean>(false);
  const [saveAndSendLoading, setSaveAndSendLoading] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [invoiceSubCategs, setInvoiceSubCategs] = useState<ISubCategory[]>([]);
  const [formData, setFormData] = useState<{
    rows: GeneralFormValues[];
    companyAddress: string | null;
    companyShareCapital: string | null;
    companyPhone: string | null;
    companySiren: string | null;
    companyTVA: string | null;
    clientName: string | null;
    clientEmail: string | null;
    clientAddress: string | null;
    clientPhone: string | null;
    clientSiren: string | null;
    clientTVA: string | null;
    additionalInfo: string | null;
    invoiceTotalHT: string | null;
    invoiceTotalTVA: string | null;
    invoiceTotalTTC: string | null;
    companyLogo: any;
    paymentLink: string | null;
    receiverEmail: string | null;
    modelRef: string | null;
  }>({
    rows: [],
    companyAddress: "",
    companyShareCapital: "",
    companyPhone: "",
    companySiren: "",
    companyTVA: "",
    clientName: "",
    clientEmail: "",
    clientAddress: "",
    clientPhone: "",
    clientSiren: "",
    clientTVA: "",
    additionalInfo: "",
    invoiceTotalHT: "",
    invoiceTotalTVA: "",
    invoiceTotalTTC: "",
    companyLogo: "",
    paymentLink: "",
    receiverEmail: "",
    modelRef: "",
  });

  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    {
      setSingleDevis(null);
      setOpenDevisModal(false);
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  const { setDecimalDigits } = useFormatter();
  const [showDropdown, setShowDropdown] = useState<DynamicObj>({});
  const [editStatusLoading, setEditStatusLoading] = useState<{
    index: string;
    value: boolean;
  } | null>();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  let api = useAxios();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const columns: any = React.useMemo(
    () => [
      {
        name: "Nº devis",
        selector: (row: any) => String(row.id).toUpperCase(),
        sortable: true,
      },
      {
        name: "Société",
        selector: (row: any) =>
          // i know it's stupid,don't judge...
          row.clientInfo &&
          row.clientInfo.clientName &&
          row.clientInfo.clientName !== ""
            ? row.clientInfo.clientName
            : "",
        sortable: true,
      },
      {
        name: "Total HT",
        selector: (row: any) => `${setDecimalDigits(row.totalHT)} €`,
        sortable: true,
      },
      {
        name: "Total TTC",
        selector: (row: any) => `${setDecimalDigits(row.totalTTC)} €`,
        sortable: true,
      },
      // {
      //   name: "Catégorie",
      //   selector: (row: any) =>
      //     row.sub_category_id ? row.sub_category.name : "",
      //   format: (row: any) => (
      //     <span title={row.sub_category_id ? row.sub_category.name : ""}>
      //       {row.sub_category_id ? row.sub_category.name : ""}
      //     </span>
      //   ),
      //   sortable: true,
      // },
      {
        name: "Statut",
        sortable: true,
        cell: (row: IDevis) => (
          <>
            {/* {creds.role === UserTypes.Client ? ( */}
            <>
              {parseInt(row.status) === 103 ? (
                <Status className="btn" type="primary" title="Accepté"></Status>
              ) : (
                <SelectableBadge
                  options={QUOTE_OPTIONS}
                  type={parseInt(row.status) === 102 ? "warning" : "danger"}
                  defaultValue={
                    QUOTE_OPTIONS.find((elt) => {
                      return row?.status
                        ? elt.value === parseInt(row?.status)
                        : elt.value === 102;
                    })!
                  }
                  onChange={async (val: any) =>
                    await editDevisStatus(row?.id!, val)
                  }
                  setShowDropdown={setShowDropdown}
                  showDropdown={showDropdown[row.id]}
                  index={row.id}
                  loading={editStatusLoading!}
                />
              )}
            </>
            {/* ) : (
              <>
                {parseInt(row.status) === 103 && (
                  <Status
                    className="btn"
                    type="primary"
                    title="Accepté"
                  ></Status>
                )}
                {parseInt(row.status) === 102 && (
                  <Status
                    className="btn"
                    type="warning"
                    title="En attente"
                  ></Status>
                )}
                {parseInt(row.status) === 104 && (
                  <Status
                    className="btn"
                    type="danger"
                    title="Non accepté"
                  ></Status>
                )}
              </>
            )} */}
          </>
        ),
      },
      {
        name: "Date de création",
        selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        omit: creds.role !== UserTypes.Client,
        cell: (row: IDevis) => (
          <>
            <div className="table-action">
              {parseInt(row.status) !== 103 && (
                <>
                  {row.clientInfo.clientEmail && (
                    <button
                      className="btn btn-blue"
                      onClick={() => {
                        setSingleDevis(row);
                        setSendDevisModal(true);
                      }}
                      title={`Renvoyer le devis ${
                        row.clientInfo.clientEmail
                          ? `à ${row.clientInfo.clientEmail}`
                          : ""
                      }`}
                    >
                      <ReviewIcon />
                    </button>
                  )}
                  <button
                    className="btn btn-blue"
                    onClick={() => {
                      setSingleDevis(row);
                      setEditDevisModal(true);
                    }}
                    title="Editer le devis"
                  >
                    <IoMdCreate />
                  </button>
                  <button
                    className="btn btn-red"
                    onClick={() => {
                      setSingleDevis(row);
                      setDeleteDevisModal(true);
                    }}
                    title="Supprimer le statut de devis"
                  >
                    <BsTrashFill />
                  </button>
                </>
              )}
            </div>
          </>
        ),
      },
    ],
    [creds.role, showDropdown]
  );

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    // await getDeviss(page);
  };

  const getInvoiceSubCategs = async () => {
    try {
      const { data } = await api.post(
        `/api/SubCategory/all`,
        {
          where: {
            isFacture: "true",
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setInvoiceSubCategs(data.data);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting invoice subCategs", error);
    }
  };

  const getDeviss = async () => {
    try {
      const { data } = await api.post(
        `/api/Devis/all`,
        {
          where: {
            companyId: company && company.id ? company?.id : userCompany.id,
          },
          perPage: 20,
          pageIndex: currentPage,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setDeviss(data.data);
      for (let elt of data.data) {
        setShowDropdown((prevState) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }
      setTotalRows(data.count);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting deviss data", error);
    }
  };

  useEffect(() => {
    getDeviss();
    getInvoiceSubCategs();
  }, [currentPage]);

  useEffect(() => {
    if (reload) {
      getDeviss();
      getInvoiceSubCategs();
      callback!(false);
    }
  }, [reload]);

  const deleteDevis = async (id: string) => {
    try {
      const { data } = await api.post(
        `/api/Devis/Delete`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getDeviss();
      setDeleteDevisModal(false);

      if (!data.deleted || data.deleted.length === 0) {
        toast.dismiss();
        toast.warning(
          `Votre devis ne pouvait pas être supprimé, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.dismiss();
        toast.success(`Votre devis a été supprimé.`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("getting deviss data", error);
    }
  };

  const editDevisStatus = async (
    id: string,
    status: SingleValue<OptionType>
  ) => {
    try {
      toast.dismiss();
      toast.warning(
        "Cela peut prendre quelques minutes, veuillez patienter...",
        {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setEditStatusLoading({
        index: id,
        value: true,
      });

      if (status?.value === 103) {
        await api.post(`/api/invoice/ownerAcceptDevis`, {
          invoiceId: id,
        });
      }

      await api.post(
        `/api/Devis/Update`,
        {
          id,
          status: status?.value,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getDeviss();
      setEditStatusLoading(null);
      toast.dismiss();
    } catch (error: any) {
      ErrorLogger("getting factures data", error);
      setEditStatusLoading(null);
      await getDeviss();
      toast.dismiss();
    }
  };

  const sendDevis = async (devis: IDevis) => {
    try {
      setSendDevisLoader(true);
      const { data } = await api.post(
        `/api/invoice/sendBillEmail`,
        {
          invoiceId: devis.id,
          invoiceModel: "devis",
          paymentLink: devis.devisInfo.paymentLink,
          isReciept: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setSendDevisLoader(false);
      setSendDevisModal(false);

      if (!data.send) {
        setEmailSendError(true);
        toast.dismiss();
        toast.warning(
          `Votre devis ne pouvait pas être envoyé, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      } else {
        toast.dismiss();
        toast.success(`Votre devis a été envoyé.`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("sending devis", error);
      setEmailSendError(true);
      setSendDevisLoader(false);
    }
  };

  const resetSearchForm = async () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      company: null,
      status: null,
    });
    await getDeviss();
  };

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchDevissFormValues>({});

  const searchDeviss: SubmitHandler<SearchDevissFormValues> = async (
    form: SearchDevissFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true);
      let payload: any = {
        companyId: company && company.id ? company?.id : userCompany.id,
      };
      if (form.dateFrom || form.dateTo)
        payload.createdAt = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };

      if (form.status) payload.status = String(form.status.value);

      const { data } = await api.post(
        `/api/Devis/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setDeviss(data.data);
      setLoading(false);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("searching deviss", error);
    }
  };

  // search email form
  const { ref: dateToRef, ...dateTo } = searchRegister("dateTo");
  const { ref: dateFromRef, ...dateFrom } = searchRegister("dateFrom");

  const itemsCreator = async (
    items: GeneralFormValues[],
    model: string,
    modelId: string
  ) => {
    try {
      let itemsCreated: any = [];
      for (let item of items) {
        const { data: itemCreated } = await api.post(
          `/api/Item/Create`,
          {
            tva: item.tva,
            totalHT: item.totalHT,
            category_id: item.category?.value,
            quantity: item.quantity,
            totalTTC: item.totalTTC,
            totalTVA: item.totalTVA,
            unitPrice: item.unitPrice,
            description: item.description,
            [model]: modelId,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (itemCreated.id) {
          itemsCreated.push(itemCreated);
        }
      }
      const ok = items.length === itemsCreated.length;
      if (ok) {
        toast.dismiss();
      } else {
        toast.dismiss();
        toast.warning(
          "Certains produits/services n'ont pas été créés avec succès",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      return ok;
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const itemUpdator = async (itemId: string, attr: string, val: any) => {
    try {
      await api.post(
        `/api/Item/Update`,
        {
          id: itemId,
          [attr]: val,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const itemsDestroyer = async (items: IItem[]) => {
    try {
      for (let item of items) {
        await api.post(
          `/api/Item/Delete`,
          {
            id: item.id,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const pdfCreator = async (devisId: string) => {
    try {
      const { data: createinvoicePdf } = await api.post(
        `/api/invoice/createInvoicePdf`,
        {
          invoiceModel: "Devis",
          invoiceId: devisId,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      return createinvoicePdf;
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const devisUpdater = async (isModel: boolean) => {
    try {
      let companyLogoURL = "";
      if (formData.companyLogo && typeof formData.companyLogo !== "string") {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        name: "Devis",
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: "devis",
        company_id: creds.company_id,
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        status: `${singleDevis?.status}`,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: singleDevis?.clientType,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
      };

      payload.devisInfo = {
        companyPhone:
          formData.companyPhone && formData.companyPhone !== ""
            ? formData.companyPhone
            : null,
        companyAddress:
          formData.companyAddress && formData.companyAddress !== ""
            ? formData.companyAddress
            : null,
        paymentLink:
          formData.paymentLink && formData.paymentLink !== ""
            ? formData.paymentLink
            : null,
        additionalInfo:
          formData.additionalInfo === "" ? null : formData.additionalInfo,
        logo: companyLogoURL !== "" ? companyLogoURL : null,
      };

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();
          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            payload.devisInfo.logo = data.urls.urls[0].url;
          }
        } else {
          payload.devisInfo.logo = formData.companyLogo;
        }
      }

      payload.id = singleDevis?.id;

      const { data } = await api.post(`/api/Devis/Update`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (singleDevis?.items) {
        if (formData.rows.length > singleDevis?.items.length) {
          const newItems = formData.rows.filter(
            (elt) =>
              !singleDevis.items
                .reduce(
                  (acc, curr) => [...acc, curr.id],
                  [] as (string | number)[]
                )
                .includes(elt.id)
          );
          await itemsCreator(newItems, `devis_id`, singleDevis?.id);
        }

        if (formData.rows.length < singleDevis?.items.length) {
          const removedItems = singleDevis?.items.filter(
            (elt) =>
              !formData.rows
                .reduce(
                  (acc, curr) => [...acc, curr.id],
                  [] as (string | number)[]
                )
                .includes(elt.id)
          );
          await itemsDestroyer(removedItems);
        }

        if (formData.rows.length === singleDevis?.items.length) {
          for (let item of formData.rows) {
            let devisItemIndex = singleDevis?.items.findIndex(
              (elt) => elt.id === item.id
            );
            if (devisItemIndex > -1) {
              const devisItem = singleDevis?.items[devisItemIndex];
              if (devisItem.category_id !== item.category?.value) {
                await itemUpdator(
                  devisItem.id,
                  "category_id",
                  item.category?.value
                );
              }
              if (devisItem.description !== item.description) {
                await itemUpdator(
                  devisItem.id,
                  "description",
                  item.description
                );
              }
              if (devisItem.unitPrice !== item.unitPrice) {
                await itemUpdator(devisItem.id, "unitPrice", item.unitPrice);
              }
              if (devisItem.quantity !== item.quantity) {
                await itemUpdator(devisItem.id, "quantity", item.quantity);
              }
              if (devisItem.tva !== item.tva) {
                await itemUpdator(devisItem.id, "tva", item.tva);
              }
              if (devisItem.totalTVA !== item.totalTVA) {
                await itemUpdator(devisItem.id, "totalTVA", item.totalTVA);
              }
              if (devisItem.totalHT !== item.totalHT) {
                await itemUpdator(devisItem.id, "totalHT", item.totalHT);
              }
              if (devisItem.totalTTC !== item.totalTTC) {
                await itemUpdator(devisItem.id, "totalTTC", item.totalTTC);
              }
            }
          }
        }

        if (!isModel) {
          await pdfCreator(singleDevis?.id!);
        }
      }

      if (data.updated.length === 0) {
        toast.dismiss();
        toast.warning(`Votre devis n'a pas été modifié!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSaveAndSendLoading(false);
        return;
      }
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const saveAsModel = async (used: boolean = false, relatedTo: string = "") => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre devis`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
        toast.dismiss();
        toast.warning(`Vous devez ajouter un nom de modèle`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNeedModelRef(true);
        return;
      }

      if (!used) {
        setSaveAsModelLoading(true);
      }

      let payload: any = {
        name: "Devis",
        modelInfo: {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: "devis",
        status: `${singleDevis?.status}`,
        company_id: creds.company_id,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: singleDevis?.clientType,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
      };

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            payload.modelInfo.logo = data.urls.urls[0].url;
          }
        } else {
          payload.modelInfo.logo = formData.companyLogo;
        }
      }

      if (relatedTo !== "") {
        payload.relatedTo = relatedTo;
      }

      const { data } = await api.post(`/api/InvoiceModel/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setSaveAsModelLoading(false);

      if (data.id) {
        const isModelCreated = await itemsCreator(
          formData.rows,
          `model_id`,
          data.id
        );
        if (isModelCreated) {
          if (!used) {
            await devisUpdater(true);
          }

          toast.dismiss();
          toast.success("Votre modéle a été créé avec succès!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.dismiss();
          toast.warning("Votre modéle n'a pas été créé!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.dismiss();
        toast.warning("Votre modéle n'a pas été créé!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (!used) {
        await getDeviss();
        await reloadParent()!;
        setTimeout(function () {
          setEditDevisModal(false);
          setSingleDevis(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving model", error);
      setSaveAsModelLoading(false);
      toast.dismiss();
      toast.warning("Votre modéle n'a pas été créé!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const saveAndSend = async (used: boolean = false) => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre devis`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }

      if (!used) {
        if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
          toast.dismiss();
          toast.warning(`Vous devez ajouter un nom de modèle`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNeedModelRef(true);
          return;
        }
        setSaveAndSendLoading(true);
        await saveAsModel(true, singleDevis?.id);
      }

      await devisUpdater(false);

      const { data: sendEmailData } = await api.post(
        `/api/invoice/sendBillEmail`,
        {
          invoiceModel: "Devis",
          invoiceId: singleDevis?.id,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          isReciept: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (sendEmailData.send) {
        toast.dismiss();
        toast.success(`Votre devis a été envoyé!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.dismiss();
        toast.warning(`Votre devis n'a pas été envoyé!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSaveAndSendLoading(false);
      if (!used) {
        await getDeviss();
        await reloadParent()!;
        setTimeout(function () {
          setEditDevisModal(false);
          setSingleDevis(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSaveAndSendLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre devis en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const send = async () => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre devis`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }
      setSendLoading(true);

      await saveAndSend(true);
      setSendLoading(false);
      await getDeviss();
      setTimeout(function () {
        setEditDevisModal(false);
        setSingleDevis(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSendLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre devis en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const download = async () => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre devis`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setDownloadLoading(true);

      await devisUpdater(false);

      const { data: devisCreated } = await api.post(
        `/api/Devis`,
        {
          id: singleDevis?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (
        devisCreated.id &&
        devisCreated.devisS3link &&
        devisCreated.devisS3link.url
      ) {
        await fileSaver(
          devisCreated.devisS3link.url,
          devisCreated.devisS3link.key
        );
      } else {
        toast.dismiss();
        toast.warning(`Votre devis n'a pas pu être traité pour le moment!`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setDownloadLoading(false);
      await getDeviss();
      setTimeout(function () {
        setEditDevisModal(false);
        setSingleDevis(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setDownloadLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre  devis en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <div className="search-top custom_search-top">
        <form onSubmit={searchHandleSubmit(searchDeviss)}>
          <Row className="align-items-end">
            <Col md={8}>
              <div className="filterInner">
                <Row>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dated">Date de début</Label>
                      <Controller
                        control={searchControl}
                        name="dateFrom"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="dated"
                        {...dateFrom}
                        innerRef={dateFromRef}
                        placeholder="Date de début"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                      {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="datef">Date de fin</Label>
                      <Controller
                        control={searchControl}
                        name="dateTo"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="datef"
                        {...dateTo}
                        innerRef={dateToRef}
                        placeholder="Date de fin"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                      {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Statut</Label>
                      <Controller
                        name="status"
                        control={searchControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={QUOTE_OPTIONS}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md={3}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Entreprise</Label>
                      <Controller
                        name="company"
                        control={searchControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            options={
                              user.role === "Cabinet"
                                ? clientsList.reduce(
                                    (acc, curr) => [
                                      ...acc,
                                      {
                                        label: curr.name || curr.email,
                                        value: curr.id,
                                      },
                                    ],
                                    [] as OptionType[]
                                  )
                                : companiesList.reduce(
                                    (acc, curr) => [
                                      ...acc,
                                      {
                                        label: curr.name || curr.email,
                                        value: curr.id,
                                      },
                                    ],
                                    [] as OptionType[]
                                  )
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={async () => await resetSearchForm()}
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <div>
        <Card className="card-Table table-primary invoice-table">
          <DataTable
            columns={columns}
            data={deviss}
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            onRowClicked={(row: IDevis, e: any) => {
              setSingleDevis(row);
              setOpenDevisModal(true);
            }}
            pagination
            progressPending={pending}
            progressComponent={
              <>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
              </>
            }
            paginationComponent={(props) => {
              const customProps = { ...props, color: "primary" };
              return <BootyPagination {...customProps} />;
            }}
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            paginationPerPage={20}
          />
        </Card>
      </div>

      <div className="openbtn text-center">
        {/*view*/}
        <Modal
          className="modal-secondary modal-dialog-centered modal-lg devis-modal"
          isOpen={openDevisModal}
          toggle={() => {
            setSingleDevis(null);
            setOpenDevisModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleDevis(null);
              setOpenDevisModal(false);
            }}
          >
            Prévisualisation de le devis {singleDevis?.id.toUpperCase()}
          </ModalHeader>
          <ModalBody>
            <div className="content-form-block">
              <FactureHtml
                invoice={singleDevis!}
                client={client}
                type="devis"
              />
            </div>
          </ModalBody>
        </Modal>
        {/*delete*/}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteDevisModal}
          toggle={() => {
            setDeleteDevisModal(false);
            setSingleDevis(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDeleteDevisModal(false);
              setSingleDevis(null);
            }}
          >
            Supprmier le devis {singleDevis?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer le devis{" "}
                {singleDevis?.id.toUpperCase()} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setDeleteDevisModal(false);
                setSingleDevis(null);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteDevis(singleDevis?.id!)}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
        {/*edit*/}
        <Modal
          className="modal-secondary modal-dialog-centered fill-invoice-modal"
          isOpen={editDevisModal}
          toggle={async () => {
            setEditDevisModal(false);
            setSingleDevis(null);
            setSaveAsModelLoading(false);
            setSaveAndSendLoading(false);
            setSendLoading(false);
            setDownloadLoading(false);
            setNeedEmail(false);
            setNeedModelRef(false);
            await getDeviss();
          }}
        >
          <ModalHeader
            toggle={async () => {
              setEditDevisModal(false);
              setSingleDevis(null);
              setSaveAsModelLoading(false);
              setSaveAndSendLoading(false);
              setSendLoading(false);
              setDownloadLoading(false);
              setNeedEmail(false);
              setNeedModelRef(false);
              await getDeviss();
            }}
          >
            Editer le devis {singleDevis?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <EditFacture
                model={"Devis"}
                invoiceSubCategs={invoiceSubCategs}
                formData={formData}
                setFormData={setFormData}
                needEmail={needEmail}
                setNeedEmail={setNeedEmail}
                clientType={{
                  label:
                    singleDevis?.clientType === ClientTypes.B2B ? "B2B" : "B2C",
                  value: singleDevis?.clientType,
                }}
                invoice={singleDevis!}
                needModelRef={needModelRef}
                setNeedModelRef={setNeedModelRef}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              type="button"
              onClick={async () => {
                setEditDevisModal(false);
                setSingleDevis(null);
                setSaveAsModelLoading(false);
                setSaveAndSendLoading(false);
                setSendLoading(false);
                setDownloadLoading(false);
                setNeedEmail(false);
                await getDeviss();
                setNeedModelRef(false);
              }}
            >
              Retour
            </Button>
            <Button
              color="success"
              outline
              type="button"
              onClick={async () => {
                await saveAsModel();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAsModelLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle"
              )}
            </Button>
            <Button
              color="primary"
              outline
              type="button"
              onClick={async () => {
                await saveAndSend();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAndSendLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle et envoyer"
              )}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={async () => {
                await send();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {sendLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Envoyer"
              )}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={async () => {
                await download();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {downloadLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Télécharger"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/*send*/}
        <Modal
          className="modal-warning modal-dialog-centered"
          isOpen={sendDevisModal}
          toggle={() => {
            setSendDevisModal(false);
            setSingleDevis(null);
            setEmailSendError(false);
            setSendDevisLoader(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSendDevisModal(false);
              setSingleDevis(null);
              setEmailSendError(false);
              setSendDevisLoader(false);
            }}
          >
            Envoyer le devis {singleDevis?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir envoyer le devis{" "}
                {singleDevis?.id.toUpperCase()} vers{" "}
                {singleDevis?.clientInfo.clientEmail ||
                  singleDevis?.clientInfo.clientCompany}{" "}
                ?
              </p>
            </div>
            {emailSendError && (
              <Alert color="danger" className="mt-2">
                Nous ne pouvons pas envoyer d'email au client pour le moment.
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              onClick={() => {
                setSendDevisModal(false);
                setSingleDevis(null);
                setEmailSendError(false);
                setSendDevisLoader(false);
              }}
              disabled={sendDevisLoader}
            >
              Non
            </Button>
            <Button
              color="warning"
              onClick={async () => await sendDevis(singleDevis!)}
              disabled={sendDevisLoader}
            >
              {sendDevisLoader ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ListeDevis;

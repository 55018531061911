import React, {ReactNode, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Row, Col, Spinner} from "reactstrap";
import FistSync from "../../assets/images/FirstSync.png";
import {ErrorLogger} from "../../util/errorLogger";
import useAxios from "../../util/hooks/useAxios";
import "./style.scss";

function FirstSyncComp({id}: {id: string}) {
  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    application_id: string;
  };

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  let api = useAxios();
  const [firstLoading, setFirstLoading] = useState<boolean>(false);

  const firstSync = async () => {
    try {
      setFirstLoading(true);
      setErrorMessage(null);
      const {data} = await api.post(
        `/api/v2/accountSync/createRedirectURL`,
        {
          companyId: id,
          syncType: "new",
          from: creds.application_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.error) {
        setFirstLoading(false);
        setErrorMessage({
          type: "first_sync_error",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }
      window.location = data.url;
      setFirstLoading(false);
    } catch (error: any) {
      ErrorLogger("creating redirect url", error);
      setFirstLoading(false);
      setErrorMessage({
        type: "first_sync_error",
        message: (
          <span>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </span>
        ),
      });
    }
  };

  return (
    <Row className="first-sync-comp">
      <Col md={6} className="first-sync-text">
        <p>
          Commencez par synchroniser votre compte bancaire avec l'application!
        </p>
        <button
          type="button"
          className="btn btn-secondary"
          disabled={firstLoading}
          onClick={async () => {
            await firstSync();
          }}
        >
          {!firstLoading ? (
            "Synchroniser votre compte"
          ) : (
            <Spinner color="light" type="border" size={"sm"}>
              Loading...
            </Spinner>
          )}
        </button>
        {errorMessage?.type === "first_sync_error" && (
          <div className="d-flex justify-content-center text-danger mt-2">
            {errorMessage?.message}
          </div>
        )}
      </Col>
      <Col md={6} className="first-sync-img">
        <img src={FistSync} alt="first sync" />
      </Col>
    </Row>
  );
}

export default FirstSyncComp;

import {useEffect, useState} from "react";
import GestionBridgeAccount from "../../AccountsChoice/GestionBridgeAccount/GestionBridgeAccount";
import {IBridgeAccount} from "../../../interfaces";
import {getBridgeAccount} from "../../../api/bridgeAccount";
import useAxios from "../../../util/hooks/useAxios";
import {toast} from "react-toastify";
import {toastSettings} from "../../../util/context";
import {useOutletContext, useParams} from "react-router";
import "./GestionCompteIban.scss";
import {useSelector} from "react-redux";
import {getClient} from "../../../api/client";

const GestionCompteIban = () => {
  const context = useOutletContext() as any;
  let api = useAxios();

  const {id} = useParams();

  const findClient = async () => {
    try {
      const {data} = await getClient({
        companyId: id!,
        api,
      });
      setClientId(data.data.id);
      setCompaniesList(data.data.companies);
    } catch (error) {}
  };

  const [ibanList, setIbanList] = useState<IBridgeAccount[]>([]);
  const [clientId, setClientId] = useState<string>("");
  const [companiesList, setCompaniesList] = useState<
    Array<Record<string, string>>
  >([]);

  const companies = useSelector(
    (state: {root: object; user: Record<string, unknown>}) =>
      state.user.userCompanies
  ) as Array<Record<string, string>>;

  const getBridgeAccountList = async () => {
    try {
      const {data} = await getBridgeAccount({clientId, api});
      setIbanList(data.data);
    } catch (error) {
      toast.dismiss();
      toast.error("votre opération a échoué", toastSettings);
    }
  };

  useEffect(() => {
    if (clientId) {
      getBridgeAccountList();
    }
  }, [clientId]);

  useEffect(() => {
    if (!!id) {
      findClient();
    } else if (!!context && companies) {
      setClientId(context.client?.client_id);
      setCompaniesList(companies);
    }
  }, [id, context, companies]);

  return (
    <div className="container">
      <div className="section-Compte-sync">
        <h3>Gestion des comptes</h3>
        {!ibanList || ibanList.length === 0 ? (
          <p style={{textAlign: "center"}}>Il n'y a aucun data à afficher</p>
        ) : (
          <GestionBridgeAccount
            ibans={ibanList}
            getBridgeAccounts={getBridgeAccountList}
            clientId={clientId}
            companiesList={companiesList}
          />
        )}
      </div>
    </div>
  );
};

export default GestionCompteIban;

import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./style.scss";
import {Button, Card, FormGroup, Input, Label, Spinner} from "reactstrap";
import juridiqueIcons from "../../../assets/images/svg/juridique-icon.svg";
import {IBridgeAccount, IRelatedAccount} from "../../../interfaces";
import useAxios from "../../../util/hooks/useAxios";
import {ErrorLogger} from "../../../util/errorLogger";
import {BsSave, BsSave2} from "react-icons/bs";
import {FaSave, FaSync} from "react-icons/fa";
import {toast} from "react-toastify";
import {toastSettings} from "../../../util/context";
import SaveIconWhite from "../../../assets/SaveIconWhite";
import {AiFillBank} from "react-icons/ai";
import {RiAccountPinCircleFill} from "react-icons/ri";
import SynchroniseBridgeAccountModal from "./SynchroniseBridgeAccountModal";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";

type Props = {
  ibans: IBridgeAccount[];
  getBridgeAccounts: () => Promise<any>;
  handelModal?: () => void;
  clientId: string;
  companiesList: Array<Record<string, string>>;
};

const GestionBridgeAccount = ({
  ibans,
  getBridgeAccounts,
  handelModal,
  clientId,
  companiesList,
}: Props) => {
  const [newBridgeAccounts, setNewBridgeAccounts] = useState<IBridgeAccount[]>(
    []
  );

  const [gestionModal, setGestionModal] = useState(false);
  const [bridgeAccount, setBridgeAccount] = useState<IBridgeAccount>(
    {} as IBridgeAccount
  );

  const [loadingState, setLoadingState] = useState({
    id: 0,
    status: false,
    role: "",
  });

  let api = useAxios();

  const handleChange = (
    name: string,
    event: string | boolean | any,
    id: number
  ) => {
    if (name === "companyId") {
      return setNewBridgeAccounts((prevValues) => {
        return prevValues.map((el) => {
          return el.id === id ? {...el, [name]: event.value} : el;
        });
      });
    }
    setNewBridgeAccounts((prevValues) => {
      return prevValues.map((el) => {
        return el.id === id ? {...el, [name]: event} : el;
      });
    });
  };

  const handleSubmit = async (id: number) => {
    // call API for update ibans
    setLoadingState({
      id: id,
      role: "update",
      status: true,
    });
    toast.dismiss();
    toast.info("Votre operation est en progress", toastSettings);
    try {
      const acc = newBridgeAccounts.find((iban) => iban.id === id);
      if (acc && acc.id) {
        const {id: bridgeAccountId, bridge_item_id, ...rest} = acc;
        await api.post("/api/v2/accountSync/syncBridgeAccount", {
          id: bridgeAccountId,
          ...rest,
        });
      }
      await getBridgeAccounts();
      if (handelModal) {
        handelModal();
      }
      toast.dismiss();
      toast.success("votre opération est réussie", toastSettings);
    } catch (error: any) {
      ErrorLogger("getting notes", error);
      toast.dismiss();
      toast.error("votre opération a échoué", toastSettings);
    }
    setLoadingState({
      id: 0,
      role: "",
      status: false,
    });
  };

  useEffect(() => {
    if (ibans && ibans.length > 0) {
      setNewBridgeAccounts(ibans);
    }
  }, [ibans]);

  const companies = useMemo(() => {
    return companiesList.map((company) => ({
      value: company.id,
      label: company.name || company.email,
    }));
  }, [companiesList]);

  return (
    <>
      <SynchroniseBridgeAccountModal
        gestionModal={gestionModal}
        setGestionModal={setGestionModal}
        bridgeAccountId={bridgeAccount.id}
        getBridgeAccounts={getBridgeAccounts}
        clientId={clientId}
        companyId={bridgeAccount.companyId}
        ibanId={bridgeAccount.iban}
      />
      {newBridgeAccounts
        .sort((a: IBridgeAccount, b: IBridgeAccount) =>
          a.name.localeCompare(b.name)
        )
        .map((account) => {
          return (
            <div className="iban-edit-wrapper" key={account.id}>
              <FormGroup
                className="form-icon icon-start "
                style={{width: "35%"}}
              >
                <Label for="name" style={{textAlign: "start"}}>
                  Nom de compte
                </Label>
                <Input
                  name="name"
                  id="name"
                  defaultValue={account.name}
                  type="text"
                  className="form-secondary"
                  onChange={(e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      account.id as any
                    )
                  }
                />
                <span className="icon icon-secondary ">
                  <RiAccountPinCircleFill />
                </span>
              </FormGroup>

              <FormGroup
                className="form-icon icon-start"
                style={{width: "35%"}}
              >
                <Label for="iban">Iban</Label>
                <Input
                  name="iban"
                  id="iban"
                  defaultValue={account.iban}
                  type="text"
                  className="form-secondary"
                  onChange={(e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      account.id as any
                    )
                  }
                  // disabled={account.iban !== ""}
                />
                <span className="icon icon-secondary ">
                  <AiFillBank />
                </span>
              </FormGroup>
              <FormGroup
                className="form-icon cell-checkbox"
                style={{width: "20%"}}
              >
                <Label>Entreprises</Label>
                <ReactSelect
                  options={companies}
                  closeMenuOnSelect={true}
                  onChange={(e) =>
                    handleChange("companyId", e, account.id as any)
                  }
                  value={
                    account.companyId
                      ? companies.find((el) => el.value === account.companyId)
                      : null
                  }
                  classNamePrefix="select"
                  className="custom-select form-secondary"
                  // isDisabled={}
                />
              </FormGroup>

              <FormGroup
                className="form-icon cell-checkbox"
                style={{width: "15%"}}
              >
                <Label for={`checkbox-${account.id}`}>Sync. Auto</Label>
                <div className="check-box check-secondary">
                  <input
                    className="styled-checkbox"
                    id={`checkbox-${account.id}`}
                    type="checkbox"
                    name="autoSync"
                    checked={account.autoSync}
                    onChange={(e) =>
                      handleChange(
                        e.target.name,
                        e.target.checked,
                        account.id as any
                      )
                    }
                  />
                  <label htmlFor={`checkbox-${account.id}`} />
                </div>
              </FormGroup>
              <FormGroup
                className="form-icon cell-checkbox"
                style={{
                  width: "10%",
                }}
              >
                <Label for={`checkbox-${account.id}`}>Sync. manuel</Label>
                <button
                  className="btn btn-blue"
                  type="submit"
                  disabled={loadingState.status}
                  onClick={() => {
                    setGestionModal(true);
                    setBridgeAccount(account);
                  }}
                  title="Synchroniser votre compte"
                >
                  <FaSync />
                </button>
              </FormGroup>
              <FormGroup
                className="form-icon icon-start"
                style={{width: "15%"}}
              >
                <Label for="iban">Enregistrer</Label>
                <button
                  onClick={() => handleSubmit(account.id as any)}
                  disabled={loadingState.status}
                  className="btn btn-blue btn-save"
                  type="submit"
                >
                  {loadingState.status && loadingState.id === account.id ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    <FaSave />
                  )}
                </button>
              </FormGroup>
            </div>
          );
        })}
    </>
  );
};

export default GestionBridgeAccount;

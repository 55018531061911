import React, { useEffect, useState } from "react";
import "./ListesAvoirs.scss";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import { BootyPagination } from "../../../components/table/pagination";
import { RiSearchLine } from "react-icons/ri";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import Select, { SingleValue } from "react-select";
import ReviewIcon from "../../../assets/ReviewIcon";
import FactureHtml from "../FactureHtml/FactureHtml";
import FactureRev from "../FactureRev/FactureRev";
import config from "../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { Company, IAvoir, User } from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import moment, { Moment } from "moment";
import {
  CREDIT_NOTE_OPTIONS,
  OptionType,
  UserTypes,
} from "../../../util/context";
import Status from "../../../components/badge/Status";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { IoMdCreate } from "react-icons/io";
import SelectableBadge from "../../../components/SelectableBadge";
import { toast, ToastContainer } from "react-toastify";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

export interface FactProps {}
type DynamicObj = {
  [prop: string]: boolean;
};

type SearchAvoirsFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  company?: OptionType | null;
  status?: OptionType | null;
};

const { API_URL } = config[process.env.NODE_ENV];

const ListesAvoirs = ({
  client,
  context,
  company,
  callback,
  reload,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: any;
  reload?: boolean;
}) => {
  const [sendAvoirLoader, setSendAvoirLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [avoirs, setAvoirs] = useState<IAvoir[]>([]);
  const [singleAvoir, setSingleAvoir] = useState<IAvoir | null>();
  const [loading, setLoading] = useState(false);
  const [openAvoirModal, setOpenAvoirModal] = useState<boolean>(false);
  const [deleteAvoirModal, setDeleteAvoirModal] = useState<boolean>(false);
  const [editAvoirModal, setEditAvoirModal] = useState<boolean>(false);
  const [sendAvoirModal, setSendAvoirModal] = useState<boolean>(false);
  const [emailSendError, setEmailSendError] = useState<boolean>(false);

  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    {
      setSingleAvoir(null);
      setOpenAvoirModal(false);
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  const { setDecimalDigits } = useFormatter();
  const [showDropdown, setShowDropdown] = useState<DynamicObj>({});
  const [editStatusLoading, setEditStatusLoading] = useState<{
    index: string;
    value: boolean;
  } | null>();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  let api = useAxios();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const columns: any = React.useMemo(
    () => [
      {
        name: "Nº avoir",
        selector: (row: any) => String(row.id).toUpperCase(),
        sortable: true,
      },
      {
        name: "Société",
        selector: (row: any) =>
          // i know it's stupid,don't judge...
          row.clientInfo &&
          row.clientInfo.clientName &&
          row.clientInfo.clientName !== ""
            ? row.clientInfo.clientName
            : "",
        sortable: true,
      },
      {
        name: "Total HT",
        selector: (row: any) => `${setDecimalDigits(row.totalHT)} €`,
        sortable: true,
      },
      {
        name: "Total TTC",
        selector: (row: any) => `${setDecimalDigits(row.totalTTC)} €`,
        sortable: true,
      },
      // {
      //   name: "Catégorie",
      //   selector: (row: any) =>
      //     row.sub_category_id ? row.sub_category.name : "",
      //   format: (row: any) => (
      //     <span title={row.sub_category_id ? row.sub_category.name : ""}>
      //       {row.sub_category_id ? row.sub_category.name : ""}
      //     </span>
      //   ),
      //   sortable: true,
      // },
      {
        name: "Statut",
        sortable: true,
        cell: (row: IAvoir) => (
          <>
            {/* {creds.role === UserTypes.Client ? ( */}
            <>
              {parseInt(row.status) === 105 ? (
                <Status
                  className="btn"
                  type="primary"
                  title="Remboursé"
                ></Status>
              ) : (
                <SelectableBadge
                  options={CREDIT_NOTE_OPTIONS}
                  type={parseInt(row.status) === 102 ? "warning" : "danger"}
                  defaultValue={
                    CREDIT_NOTE_OPTIONS.find((elt) => {
                      return row?.status
                        ? elt.value === parseInt(row?.status)
                        : elt.value === 102;
                    })!
                  }
                  onChange={async (val: any) =>
                    await editAvoirStatus(row?.id!, val)
                  }
                  setShowDropdown={setShowDropdown}
                  showDropdown={showDropdown[row.id]}
                  index={row.id}
                  loading={editStatusLoading!}
                />
              )}
            </>
            {/* ) : (
              <>
                {parseInt(row.status) === 105 && (
                  <Status
                    className="btn"
                    type="primary"
                    title="Remboursé"
                  ></Status>
                )}
                {parseInt(row.status) === 102 && (
                  <Status
                    className="btn"
                    type="warning"
                    title="En attente"
                  ></Status>
                )}
                {parseInt(row.status) === 106 && (
                  <Status
                    className="btn"
                    type="danger"
                    title="Non remboursé"
                  ></Status>
                )}
              </>
            )} */}
          </>
        ),
      },
      {
        name: "Date de création",
        selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        omit: creds.role !== UserTypes.Client,
        cell: (row: IAvoir) => (
          <>
            <div className="table-action">
              {parseInt(row.status) !== 105 && (
                <>
                  {row.clientInfo.clientEmail && (
                    <button
                      className="btn btn-blue"
                      onClick={() => {
                        setSingleAvoir(row);
                        setSendAvoirModal(true);
                      }}
                      title={`Renvoyer l'avoir ${
                        row.clientInfo.clientEmail
                          ? `à ${row.clientInfo.clientEmail}`
                          : ""
                      }`}
                    >
                      <ReviewIcon />
                    </button>
                  )}
                  {/* <button
                  className="btn btn-blue"
                  onClick={() => {
                    setSingleAvoir(row);
                    setEditAvoirModal(true);
                  }}
                  title="Editer le statut de l'avoir"
                >
                  <IoMdCreate />
                </button> */}
                  {/* <button
                  className="btn btn-red"
                  onClick={() => {
                    setSingleAvoir(row);
                    setDeleteAvoirModal(true);
                  }}
                  title="Supprimer l'avoir"
                >
                  <BsTrashFill />
                </button> */}
                </>
              )}
            </div>
          </>
        ),
      },
    ],
    [creds.role, showDropdown]
  );

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    // await getAvoirs(page);
  };

  const getAvoirs = async () => {
    try {
      const { data } = await api.post(
        `/api/Avoir/all`,
        {
          where: {
            companyId: company && company.id ? company?.id : userCompany.id,
          },
          perPage: 20,
          pageIndex: currentPage,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setAvoirs(data.data);
      for (let elt of data.data) {
        setShowDropdown((prevState) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }
      setTotalRows(data.count);
      setPending(false)
    } catch (error: any) {
      setPending(false)
      ErrorLogger("getting avoirs data", error);
    }
  };

  const deleteAvoir = async (id: string) => {
    try {
      const { data } = await api.post(
        `/api/Avoir/Delete`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getAvoirs();
      setDeleteAvoirModal(false);
      if (!data.deleted || data.deleted.length === 0) {
        toast.dismiss();
        toast.warning(
          `Votre avoir ne pouvait pas être supprimé, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.dismiss();
        toast.success(`Votre avoir a été supprimé.`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("getting avoirs data", error);
    }
  };

  const editAvoirStatus = async (
    id: string,
    status: SingleValue<OptionType>
  ) => {
    try {
      toast.dismiss();
      toast.warning(
        "Cela peut prendre quelques minutes, veuillez patienter...",
        {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setEditStatusLoading({
        index: id,
        value: true,
      });

      await api.post(
        `/api/Avoir/Update`,
        {
          id,
          status: status?.value,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getAvoirs();
      setEditStatusLoading(null);
      toast.dismiss();
    } catch (error: any) {
      ErrorLogger("getting factures data", error);
      setEditStatusLoading(null);
    }
  };

  useEffect(() => {
    getAvoirs();
  }, [currentPage]);

  useEffect(() => {
    if (reload) {
      getAvoirs();
      callback!(false);
    }
  }, [reload]);

  const sendAvoir = async (avoir: IAvoir) => {
    try {
      setSendAvoirLoader(true);
      const { data } = await api.post(
        `/api/invoice/sendBillEmail`,
        {
          invoiceId: avoir.id,
          invoiceModel: "avoir",
          paymentLink: avoir.avoirInfo.paymentLink,
          isReciept: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSendAvoirModal(false);
      setSendAvoirLoader(false);
      if (!data.send) {
        setEmailSendError(true);
        toast.dismiss();
        toast.warning(
          `Votre avoir ne pouvait pas être envoyé, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      } else {
        toast.dismiss();
        toast.success(`Votre avoir a été envoyée.`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("sending avoir", error);
      setEmailSendError(true);
      setSendAvoirLoader(false);
    }
  };

  const resetSearchForm = async () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      company: null,
      status: null,
    });
    await getAvoirs();
  };

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchAvoirsFormValues>({});

  const searchAvoirs: SubmitHandler<SearchAvoirsFormValues> = async (
    form: SearchAvoirsFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true)
      let payload: any = {
        companyId: company && company.id ? company?.id : userCompany.id,
      };
      if (form.dateFrom || form.dateTo)
        payload.createdAt = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };

      if (form.status) payload.status = String(form.status.value);

      const { data } = await api.post(
        `/api/Avoir/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setAvoirs(data.data);
      setLoading(false);
      setPending(false)
    } catch (error: any) {
      setPending(false)
      ErrorLogger("searching avoirs", error);
    }
  };

  // search email form
  const { ref: dateToRef, ...dateTo } = searchRegister("dateTo");
  const { ref: dateFromRef, ...dateFrom } = searchRegister("dateFrom");

  return (
    <div>
      
      <div className="search-top custom_search-top">
        <form onSubmit={searchHandleSubmit(searchAvoirs)}>
          <Row className="align-items-end">
            <Col md={8}>
              <div className="filterInner">
                <Row>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dated">Date de début</Label>
                      <Controller
                        control={searchControl}
                        name="dateFrom"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="dated"
                        {...dateFrom}
                        innerRef={dateFromRef}
                        placeholder="Date de début"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                     {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="datef">Date de fin</Label>
                      <Controller
                        control={searchControl}
                        name="dateTo"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="datef"
                        {...dateTo}
                        innerRef={dateToRef}
                        placeholder="Date de fin"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                     {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Statut</Label>
                      <Controller
                        name="status"
                        control={searchControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={CREDIT_NOTE_OPTIONS}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md={3}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Entreprise</Label>
                      <Controller
                        name="company"
                        control={searchControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            options={
                              user.role === "Cabinet"
                                ? clientsList.reduce(
                                    (acc, curr) => [
                                      ...acc,
                                      {
                                        label: curr.name || curr.email,
                                        value: curr.id,
                                      },
                                    ],
                                    [] as OptionType[]
                                  )
                                : companiesList.reduce(
                                    (acc, curr) => [
                                      ...acc,
                                      {
                                        label: curr.name || curr.email,
                                        value: curr.id,
                                      },
                                    ],
                                    [] as OptionType[]
                                  )
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col> */}
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={async () => await resetSearchForm()}
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <div>
        <Card className="card-Table table-primary invoice-table">
          <DataTable
            columns={columns}
            data={avoirs}
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            onRowClicked={(row: IAvoir, e: any) => {
              setSingleAvoir(row);
              setOpenAvoirModal(true);
            }}
            pagination
            progressPending={pending}
            progressComponent={
              <>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
              </>
            }
            paginationComponent={(props) => {
              const customProps = { ...props, color: "primary" };
              return <BootyPagination {...customProps} />;
            }}
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            paginationPerPage={20}
          />
        </Card>
      </div>

      <div className="openbtn text-center">
        {/*view*/}
        <Modal
          className="modal-secondary modal-dialog-centered modal-lg avoir-modal"
          isOpen={openAvoirModal}
          toggle={() => {
            setSingleAvoir(null);
            setOpenAvoirModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleAvoir(null);
              setOpenAvoirModal(false);
            }}
          >
            Prévisualisation de l'avoir {singleAvoir?.id.toUpperCase()}
          </ModalHeader>
          <ModalBody>
            <div className="content-form-block">
              <FactureHtml
                invoice={singleAvoir!}
                client={client}
                type="avoir"
              />
            </div>
          </ModalBody>
        </Modal>
        {/*delete*/}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteAvoirModal}
          toggle={() => {
            setDeleteAvoirModal(false);
            setSingleAvoir(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDeleteAvoirModal(false);
              setSingleAvoir(null);
            }}
          >
            Supprmier l'avoir {singleAvoir?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5 ">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer l'avoir{" "}
                {singleAvoir?.id.toUpperCase()} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setDeleteAvoirModal(false);
                setSingleAvoir(null);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteAvoir(singleAvoir?.id!)}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
        {/*edit*/}
        <Modal
          className="modal-secondary modal-dialog-centered status-change-modal"
          isOpen={editAvoirModal}
          toggle={() => {
            setEditAvoirModal(false);
            setSingleAvoir(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setEditAvoirModal(false);
              setSingleAvoir(null);
            }}
          >
            Editer le statut de l'avoir {singleAvoir?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <div className="acoounts-choice multiple-sync-wrapper ">
                <div className="bank-wrapper">
                  <FormGroup>
                    <Label>Sélectionner un statut</Label>
                    <Select
                      options={CREDIT_NOTE_OPTIONS}
                      closeMenuOnSelect={true}
                      classNamePrefix="select"
                      className="custom-select form-secondary"
                      onChange={async (status) =>
                        await editAvoirStatus(singleAvoir?.id!, status!)
                      }
                      defaultValue={CREDIT_NOTE_OPTIONS.find(
                        (elt) => elt.value === parseInt(singleAvoir?.status!)
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setEditAvoirModal(false);
                setSingleAvoir(null);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteFacture(singleAvoir?.id!)}
            >
              Oui
            </Button>
          </ModalFooter> */}
        </Modal>
        {/*send*/}
        <Modal
          className="modal-warning modal-dialog-centered"
          isOpen={sendAvoirModal}
          toggle={() => {
            setSendAvoirModal(false);
            setSingleAvoir(null);
            setEmailSendError(false);
            setSendAvoirLoader(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSendAvoirModal(false);
              setSingleAvoir(null);
              setEmailSendError(false);
              setSendAvoirLoader(false);
            }}
          >
            Envoyer l'avoir {singleAvoir?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir envoyer l'avoir{" "}
                {singleAvoir?.id.toUpperCase()} vers{" "}
                {singleAvoir?.clientInfo.clientEmail ||
                  singleAvoir?.clientInfo.clientCompany}{" "}
                ?
              </p>
            </div>
            {emailSendError && (
              <Alert color="danger" className="mt-2">
                Nous ne pouvons pas envoyer d'email au client pour le momen.
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              onClick={() => {
                setSendAvoirModal(false);
                setSingleAvoir(null);
                setEmailSendError(false);
                setSendAvoirLoader(false);
              }}
              disabled={sendAvoirLoader}
            >
              Non
            </Button>
            <Button
              color="warning"
              onClick={async () => await sendAvoir(singleAvoir!)}
              disabled={sendAvoirLoader}
            >
              {sendAvoirLoader ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ListesAvoirs;
